import { nanoid as uniqueId } from "nanoid";
import * as React from "react";

export default function useUniqueId(): string {
  let ref = React.useRef<string>();
  if (ref.current == null) {
    ref.current = uniqueId();
  }
  return ref.current;
}
