import create from "zustand";

import createStore from "./create";
import LocalStateMiddleWare from "./LocalSaveMiddleware";
import type { MainStore } from "./types";

// Putting this in a variable first to enable autocompletion.
const useMainStore = create<MainStore>(LocalStateMiddleWare(createStore));

export default useMainStore;
export type { MainStore };
