import "@fontsource/libre-franklin";

import { ThemeProvider } from "@emotion/react";
import { Global } from "@emotion/react";
import styled from "@emotion/styled";
import { animated, useSpring } from "@react-spring/web";
import * as React from "react";
import shallow from "zustand/shallow";

import useMainStore, { MainStore } from "../stores/main";
import useModelStore from "../stores/models";
import { ModelStore } from "../stores/models/types";
import theme from "../theme";
import Controls from "./Controls";
import DesignSelect from "./designSelect/DesignSelect";
import FormFactorSelect from "./FormFactorSelect";
import Loading from "./Loading";
import Menu from "./menu/Menu";
import Simulation from "./Simulation";
import ToastContainer from "./ToastContainer";

const Header = styled.div({
  zIndex: 100,
  boxSizing: "border-box",
  width: "100%",
  position: "fixed",
  top: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  pointerEvents: "none",
});

const HeaderMenuDiv = styled.div(({ theme }) => ({
  margin: theme.space[2],
  marginRight: 0,
  flexShrink: 0,
}));

const HeaderViewsDiv = styled.div({ flexGrow: 1 });

const HeaderControlsDiv = styled.div({});

const DesignsDiv = styled.div(({ theme }) => ({
  margin: theme.space[2],
  marginBottom: 0,
}));

const Footer = styled(animated.footer)((props) => ({
  position: "fixed",
  bottom: 0,
  width: "100%",
  pointerEvents: "none",
}));

const FooterLine2Div = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  boxSizing: "border-box",
});

const Version = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.negative[2],
  margin: theme.space[2],
}));

const ToastDiv = styled.div(({ theme }) => ({
  position: "fixed",
  zIndex: 1000,
  bottom: theme.space[3],
  left: theme.space[3],
  right: theme.space[3],
}));

const localSaveStatusSelector = (store: MainStore) => store.localSaveStatus;
const modelStoreSelector = (store: ModelStore) => ({
  status: store.status,
  load: store.load,
});

export default function App(): React.ReactElement {
  const localSaveStatus = useMainStore(localSaveStatusSelector);
  const { status: modelsStatus, load: loadModels } = useModelStore(
    modelStoreSelector,
    shallow,
  );

  React.useEffect(() => {
    if (modelsStatus === "idle") {
      loadModels();
    }
  }, [modelsStatus, loadModels]);

  let isLoading =
    localSaveStatus === "loading" ||
    localSaveStatus === "idle" ||
    modelsStatus === "loading" ||
    modelsStatus === "idle";

  let appContent: React.ReactElement;

  if (isLoading) {
    appContent = <Loading />;
  } else if (modelsStatus === "crashed") {
    appContent = <>Could not load the simulation...</>;
  } else {
    appContent = <Content />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={{
          body: {
            fontFamily: "Libre Franklin",
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
            fontSize: theme.fontSizes.body,
            color: theme.colors.negative[0],
            background: "#000",
          },
          "body, html, #root": {
            height: "100%",
          },
        }}
      />
      {appContent}
      <ToastDiv>
        <ToastContainer />
      </ToastDiv>
    </ThemeProvider>
  );
}

const operationModeSelector = (store: MainStore) => store.operationMode;

function Content() {
  const operationMode = useMainStore(operationModeSelector);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const footerStyle = useSpring({
    marginLeft: isMenuOpen ? theme.sizes[5] + theme.space[2] : 0,
    delay: isMenuOpen ? 0 : 100,
  });

  return (
    <>
      <Header>
        <HeaderMenuDiv>
          <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </HeaderMenuDiv>
        <HeaderViewsDiv></HeaderViewsDiv>
        {operationMode === "debug" && (
          <HeaderControlsDiv>
            <Controls />
          </HeaderControlsDiv>
        )}
      </Header>
      <Simulation />
      <Footer style={footerStyle}>
        <DesignsDiv>
          <DesignSelect />
        </DesignsDiv>
        <FooterLine2Div>
          <FormFactorSelect />
          {operationMode === "debug" && (
            <Version>v{process.env.REACT_APP_VERSION}</Version>
          )}
        </FooterLine2Div>
      </Footer>
    </>
  );
}
