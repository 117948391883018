import styled from "@emotion/styled";
import { useSpring } from "@react-spring/web";
import * as React from "react";
import shallow from "zustand/shallow";

import Simulator from "../Simulator/Simulator";
import useMainStore, { MainStore } from "../stores/main";
import { DesignOrientation } from "../stores/main/types";
import useModelStore from "../stores/models";

const Canvas = styled.canvas({
  width: "100%",
  height: "100%",
  display: "block",
});

export default function Simulation(): React.ReactElement {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const simulatorRef = React.useRef<Simulator | null>(null);
  const formFactor = useMainStore(formFactorSelector, shallow);
  useSpring({
    config: { mass: 2, tension: 170, friction: 35 },
    to: formFactor,
    onChange(animResult) {
      simulatorRef.current?.updateComputerSettings(animResult.value);
    },
  });

  // Create the simulator.
  React.useLayoutEffect(() => {
    if (canvasRef.current == null) {
      throw new Error(`Unexpected empty canvas reference`);
    }
    const mainState = useMainStore.getState();
    const { models } = useModelStore.getState();
    if (models == null) {
      throw new Error(`Models are not available.`);
    }

    let displayParameter = computerDisplayArgsSelector(mainState);
    let simulator = new Simulator(canvasRef.current, {
      computer: {
        ...formFactorSelector(mainState),
        isDisplayOn: displayParameter != null,
        initCanvas: (canvas) => {
          let displayParameter = computerDisplayArgsSelector(mainState);
          if (displayParameter == null) return;
          drawDisplay(
            canvas,
            displayParameter.display,
            displayParameter.displayRotation,
          );
        },
      },
      showAxisHelper: mainState.operationMode === "debug",
      showLightGuide: mainState.operationMode === "debug",
      constrainControls: mainState.operationMode !== "debug",
      ambientLightIntensity: mainState.operationMode === "debug" ? 0.05 : 0,
      useDevicePixelRatio: true,
      models,
    });

    const unsubscribeToDisplay = useMainStore.subscribe((displayParameter) => {
      if (displayParameter == null) {
        simulator.setIsComputerDisplayOn(false);
        simulator.updateComputerDisplay(clearDisplay);
        return;
      }
      if (!simulator.getIsComputerDisplayOn()) {
        simulator.setIsComputerDisplayOn(true);
      }
      simulator.updateComputerDisplay((canvas) => {
        clearDisplay(canvas);
        drawDisplay(
          canvas,
          displayParameter.display,
          displayParameter.displayRotation,
        );
      });
    }, computerDisplayArgsSelector);

    simulatorRef.current = simulator;

    return () => {
      unsubscribeToDisplay();
      simulator.stop();
    };
  }, []);

  return <Canvas ref={canvasRef} />;
}

function formFactorSelector(store: MainStore) {
  return (
    store.getDesign(store.currentDesignId)?.formFactor ??
    store.defaultFormFactor
  );
}

function computerDisplayArgsSelector(store: MainStore) {
  let currentDesign = store.getDesign(store.currentDesignId);
  if (currentDesign == null) return null;
  return {
    display: currentDesign.image ?? null,
    displayRotation: currentDesign.orientation,
  };
}

function drawDisplay(
  canvas: HTMLCanvasElement,
  display: HTMLImageElement,
  orientation: DesignOrientation,
) {
  let context = canvas.getContext("2d");
  if (context == null) {
    throw new Error(`Could not get canvas's context`);
  }
  context.save();

  context.translate(canvas.width / 2, canvas.height / 2);

  let scale = Math.min(
    canvas.width / display.width,
    canvas.height / display.height,
  );
  if (orientation === "landscape") {
    scale = Math.min(
      canvas.width / display.height,
      canvas.height / display.width,
    );
    context.rotate(Math.PI / 2);
  }
  context.scale(scale, scale);

  context.drawImage(display, -display.width / 2, -display.height / 2);

  context.restore();
}

function clearDisplay(canvas: HTMLCanvasElement) {
  canvas.getContext("2d")?.clearRect(0, 0, canvas.width, canvas.height);
}
