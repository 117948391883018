import { css, SerializedStyles, Theme } from "@emotion/react";

export default function buttonCSS(theme: Theme): SerializedStyles {
  return css({
    display: "block",
    transition: theme.transitions[1],
    padding: `${theme.space[2]}px ${theme.space[3]}px`,
    color: theme.colors.negative[0],
    margin: `${theme.space[2]}px 0`,
    textAlign: "left",
    cursor: "pointer",
    background: "transparent",
    border: "none",
    width: "100%",
    whiteSpace: "nowrap",
    "&:hover": {
      background: theme.colors.positive[2],
    },
    "&:active": {
      background: theme.colors.accent[0],
      transition: theme.transitions[0],
    },
    "@supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)))":
      {
        "&:hover": {
          background: `${theme.colors.positive[2]}${theme.colors.transparencySuffixes[0]}`,
        },
        "&:active": {
          background: `${theme.colors.accent[0]}${theme.colors.transparencySuffixes[0]}`,
        },
      },
  });
}
