import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  animated,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from "@react-spring/web";
import { saveAs } from "file-saver";
import * as React from "react";
import shallow from "zustand/shallow";

import useMainStore, { MainStore } from "../../stores/main";
import useToast from "../../stores/toasts";
import { Export as IconExport, Import as IconImport } from "../icons";
import AnimatedMenuIcon from "./AnimatedMenuIcon";
import MenuItem, { MenuItemProps } from "./MenuItem";

const Main = styled(animated.div)(({ theme }) => ({
  display: "inline-block",
  borderRadius: theme.borderRadiuses[1],
  background: theme.colors.positive[0],
  pointerEvents: "auto",
  overflow: "hidden",

  "@supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)))":
    {
      background: `${theme.colors.positive[0]}${theme.colors.transparencySuffixes[0]}`,
      backdropFilter: `blur(${theme.blurs[5]}px)`,
      WebkitBackdropFilter: `blur(${theme.blurs[5]}px)`,
    },
}));

const MenuHeader = styled.div({
  textAlign: "right",
});

const MenuButton = styled(animated.button, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{
  isOpen: boolean;
}>(({ theme, isOpen }) => ({
  color: theme.colors.negative[0],
  transition: theme.transitions[1],
  padding: `${theme.space[2]}px`,
  cursor: "pointer",
  borderRadius: theme.borderRadiuses[1],
  background: "transparent",
  border: "none",
  "&:hover": { background: isOpen ? "" : theme.colors.positive[2] },
  "@supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)))":
    {
      "&:hover": {
        background: isOpen
          ? ""
          : `${theme.colors.positive[2]}${theme.colors.transparencySuffixes[0]}`,
      },
    },
}));

type MenuProps = {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
};

const selector = ({ importStore, exportStore }: MainStore) => ({
  importStore,
  exportStore,
});
export default function Menu({
  isMenuOpen,
  setIsMenuOpen,
}: MenuProps): React.ReactElement {
  const { importStore, exportStore } = useMainStore(selector, shallow);
  const theme = useTheme();
  const toast = useToast();
  let baseSize = theme.sizes[1] + theme.space[2] * 2;
  let iconSize = theme.sizes[1];

  const widthSpringRef = useSpringRef();
  const { width } = useSpring({
    to: isMenuOpen ? { width: theme.sizes[5] } : { width: baseSize },
    ref: widthSpringRef,
  });
  const heightSpringRef = useSpringRef();
  const { height } = useSpring({
    to: isMenuOpen
      ? { height: `calc(100vh + ${-theme.space[2] * 2}px)` }
      : { height: `calc(0vh + ${baseSize}px)` },
    ref: heightSpringRef,
  });

  const menuItems: Array<MenuItemProps & { key: string }> = [
    {
      key: "export",
      label: "export",
      iconComponent: IconExport,
      type: "button",
      onSelect: () => {
        exportStore()
          .then((blob) => saveAs(blob, "next-pc-export.json"))
          .catch((error: Error) => toast(error.message, "error"));
      },
    },
    {
      key: "import",
      label: "import",
      iconComponent: IconImport,
      type: "file input",
      accept: "application/json",
      onChange: (files) => {
        if (files == null || files.length === 0) return;
        importStore(files[0]).catch((err: Error) =>
          toast(err.message, "error"),
        );
      },
    },
  ];
  const transitionRef = useSpringRef();
  const transitions = useTransition(isMenuOpen ? menuItems : [], {
    keys: (item) => item.key,
    trail: isMenuOpen ? 200 : 0 / menuItems.length,
    from: {
      opacity: 0,
      transform: `translateX(${theme.space[4]}px)`,
      filter: `blur(${theme.blurs[1]}px)`,
    },
    enter: { opacity: 1, transform: "translateX(0px)", filter: `blur(0px)` },
    leave: {
      opacity: 0,
      transform: `translateX(0px)`,
      filter: `blur(${theme.blurs[1]}px)`,
    },
    ref: transitionRef,
  });

  useChain(
    isMenuOpen
      ? [widthSpringRef, heightSpringRef, transitionRef]
      : [transitionRef, heightSpringRef, widthSpringRef],
    isMenuOpen ? [0, 0.1, 0.2] : [0, 0, 0.1],
  );

  return (
    <Main style={{ width, height }}>
      <MenuHeader>
        <MenuButton
          isOpen={isMenuOpen}
          type="button"
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <AnimatedMenuIcon
            icon={isMenuOpen ? "cross" : "handburger"}
            size={iconSize}
          />
        </MenuButton>
      </MenuHeader>
      {transitions(
        (style, item) =>
          item && (
            <animated.div style={style} key={item.key}>
              <MenuItem {...item} />
            </animated.div>
          ),
      )}
    </Main>
  );
}
