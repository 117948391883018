import { StateCreator } from "zustand";

import loadModels from "./loadModels";
import { ModelStore } from "./types";

const createStore: StateCreator<ModelStore> = (set, get, api) => ({
  status: "idle",
  error: null,
  models: null,

  load() {
    set({ status: "loading" });
    loadModels()
      .then((models) => {
        set({ models, status: "loaded" });
      })
      .catch((error) => {
        set({ error, status: "crashed" });
      });
  },
});

export default createStore;
