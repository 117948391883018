import styled from "@emotion/styled";
import * as React from "react";

import useUniqueId from "../../hooks/useUniqueId";
import { FORM_FACTORS } from "../../lib/form-factors";
import loadImage from "../../lib/loadImage";
import { readFileAsDataURL } from "../../lib/readFileAsDataURL";
import useMainStore, { MainStore } from "../../stores/main";
import useToast from "../../stores/toasts";

const AddInputLabel = styled.label(({ theme }) => ({
  display: `block`,
  borderRadius: "100%",
  width: theme.sizes[2],
  height: theme.sizes[2],
  lineHeight: `${theme.sizes[2]}px`,
  textAlign: `center`,
  fontSize: theme.sizes[2] - theme.space[1] * 2,
  cursor: `pointer`,
  // Matches usual vignette size.
  marginBottom: theme.sizes[4] / 2 - theme.sizes[2] / 2,
  pointerEvents: `auto`,

  color: theme.colors.negative[0],
  background: theme.colors.positive[0],

  "@supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)))":
    {
      background: `${theme.colors.positive[0]}${theme.colors.transparencySuffixes[0]}`,
      backdropFilter: `blur(${theme.blurs[5]}px)`,
      WebkitBackdropFilter: `blur(${theme.blurs[5]}px)`,
    },

  "&:hover": {
    background: theme.colors.positive[2],
    "@supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)))":
      {
        background: `${theme.colors.positive[2]}${theme.colors.transparencySuffixes[0]}`,
      },
  },

  'input[type="file"]': {
    display: `none`,
  },
}));

const selector = (store: MainStore) => store.addDesign;
export default function AddDesignButton(): React.ReactElement {
  const addDesign = useMainStore(selector);
  const id = useUniqueId();
  const toast = useToast();

  let handleFileChange = (event: React.ChangeEvent) => {
    let target = event.target as HTMLInputElement;
    if (target.files != null) {
      Array.from(target.files).forEach((file) => {
        if (file.type != null && !file.type.includes("image")) {
          toast(`File is not an image: ${file.name}.`, "error");
          return;
        }
        readFileAsDataURL(file)
          .then(loadImage)
          .then((image) =>
            addDesign(
              file.name,
              image,
              image.width > image.height ? "landscape" : "portrait",
              FORM_FACTORS.closed.values,
            ),
          )
          .catch((error: Error) => {
            toast(error.message, "error");
          });
      });
    }
  };

  return (
    <AddInputLabel htmlFor={id}>
      +
      <input id={id} type="file" accept="image/*" onChange={handleFileChange} />
    </AddInputLabel>
  );
}
