import Dexie from "dexie";
import { exportDB, importDB } from "dexie-export-import";
import { sortBy } from "lodash";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import pThrottle from "p-throttle";
import { Except } from "type-fest";
import { GetState, SetState, StateCreator, StoreApi } from "zustand";

import { FORM_FACTORS } from "../../lib/form-factors";
import loadImage from "../../lib/loadImage";
import { ComputerFormFactor, DesignRecord, MainStore } from "./types";

const STATE_ID = 0;

type IDesign = Except<DesignRecord, "image"> & { url: string };

type IState = {
  id: number;
  designOrder: Array<string>;
  defaultFormFactor: ComputerFormFactor;
  currentDesignId: string | null;
};

const TEMPLATE_DESIGN: IDesign = {
  id: "template",
  fileName: "DTSL_template.png",
  orientation: "portrait",
  url: "DTSL_template.png",
  formFactor: FORM_FACTORS.closed.values,
};

export default function LocalStateMiddleWare(config: StateCreator<MainStore>) {
  return (
    set: SetState<MainStore>,
    get: GetState<MainStore>,
    api: StoreApi<MainStore>,
  ): MainStore => {
    let db = new Database();

    const updateStoreFromDB = async () => {
      try {
        set({ localSaveStatus: "loading" });
        let [dbDesigns, dbState] = await Promise.all([
          db.designs.toArray(),
          db.state.get(STATE_ID),
        ]);

        if (dbState == null) {
          let image = await loadImage(TEMPLATE_DESIGN.url);
          let { url, ...templateRecord } = { ...TEMPLATE_DESIGN, image };
          let designs = new Map();
          designs.set(TEMPLATE_DESIGN.id, templateRecord);
          set({
            designs,
            lookedUpDesignId: null,
            currentDesignId: TEMPLATE_DESIGN.id,
            designOrder: [TEMPLATE_DESIGN.id],
          });
        } else {
          let designList = await Promise.all(
            dbDesigns.map(async ({ url, id, ...d }) => {
              return { id, ...d, image: await loadImage(url) };
            }),
          );
          set({
            ...omit(dbState, "id"),
            designs: new Map(designList.map((d) => [d.id, d])),
            lookedUpDesignId: null,
          });
        }
        set({ localSaveStatus: "loaded" });
      } catch (error) {
        set({ localSaveError: error, localSaveStatus: "crashed" });
      }
    };

    const throttle = pThrottle({ interval: 500, limit: 1 });

    const save = throttle(async () => {
      try {
        await db.transaction("rw", db.designs, db.state, async () => {
          let savedDesigns = await db.designs.toArray();
          if (savedDesigns == null) {
            savedDesigns = [];
          }
          let newStore = get();
          let savedDesignsMap = new Map(savedDesigns.map((d) => [d.id, d]));
          let newDesignsMap = newStore.designs;
          let changedDesigns = Array.from(newDesignsMap.values()).filter(
            (d1) => {
              let d2 = savedDesignsMap.get(d1.id);
              return d2 == null || !isEqual(d2, d1);
            },
          );
          let removedDesigns = savedDesigns.filter(
            (d1) => !newDesignsMap.has(d1.id),
          );
          await Dexie.Promise.all([
            db.designs.bulkPut(
              changedDesigns.map(({ image, ...d }) => ({
                url: image.src,
                ...d,
              })),
            ),
            db.designs.bulkDelete(removedDesigns.map((d) => d.id)),
            db.state.put({
              id: STATE_ID,
              currentDesignId: newStore.currentDesignId,
              defaultFormFactor: newStore.defaultFormFactor,
              designOrder: newStore.designOrder,
            }),
          ]);
        });
        set({ localSaveError: null, localSaveStatus: "loaded" });
      } catch (error) {
        set({ localSaveError: error, localSaveStatus: "crashed" });
      }
    });

    function exportStore() {
      return exportDB(db, { prettyJson: false });
    }

    async function importStore(file: File) {
      set({ localSaveStatus: "loading" });
      try {
        // Completely delete the current data base. Import should overwrite
        // everything anyway.
        await db.delete();
        // Export file may be outdated. For this reason, we cannot use
        // importInto. Instead, we import the new database in a new (default)
        // Dexie instance to save the potentially outdated data in instance db.
        let newDb = await importDB(file);
        await newDb.close();
        // Recreate the database using our custom database constructor. This
        // will trigger the version upgrade if needed.
        db = new Database();
        await updateStoreFromDB();
      } catch (error) {
        await db.delete();
        db = new Database();
        await save();
        throw new Error(`Could not import ${file.name}...`);
      }
    }

    async function persist() {
      await navigator.storage?.persist?.();
      let isDataPersisted = await navigator.storage?.persisted?.();
      if (!isDataPersisted) {
        // eslint-disable-next-line no-console
        console.warn(
          "Data may be cleared by the navigator under storage pressure.",
        );
      }
    }

    persist();
    updateStoreFromDB();

    return {
      ...config(
        (...args) => {
          set(...args);
          if (get().localSaveStatus !== "loading") save();
        },
        get,
        api,
      ),
      exportStore,
      importStore,
      localSaveStatus: "loading",
      localSaveError: null,
    };
  };
}

class Database extends Dexie {
  designs: Dexie.Table<IDesign, string>;
  state: Dexie.Table<IState, number>;

  constructor() {
    super("Next PC Simulation Database");

    this.version(3)
      .stores({ designs: "id", state: "id" })
      .upgrade((tx) =>
        Dexie.Promise.all([
          tx
            .table("state")
            .toCollection()
            .modify((state) => {
              // Orientation was in state v1.
              delete state.orientation;
            }),
          tx
            .table("designs")
            .toCollection()
            .modify((design) => {
              design.url = design.dataURL;
              delete design.dataURL;
            }),
        ]),
      );

    this.version(4)
      .stores({ designs: "id", state: "id" })
      .upgrade((tx) =>
        tx
          .table("designs")
          .toCollection()
          .modify((design) => {
            // design.orientatino should never be set here, but just in case
            // we make sure not to overwrite it.
            // TODO: ideally we would like to check the size of the image
            // before setting the orientation, unfortunately I could not
            // find a way to make upgrade asynchronous...
            design.orientation = design.orientation ?? "portrait";
          }),
      );

    this.version(5)
      .stores({ designs: "id", state: "id" })
      .upgrade((tx) =>
        tx
          .table("state")
          .get(STATE_ID)
          .then((state) => {
            const defaultFormFactor: ComputerFormFactor = {
              openingAngle: state.openingAngle,
              sidePositionProgress: state.sidePositionProgress,
              landscapeOrientationProgress: state.landscapeOrientationProgress,
              reversedPositionProgress: state.reversedPositionProgress,
            };
            return Dexie.Promise.all([
              tx
                .table("state")
                .toCollection()
                .modify((state) => {
                  state.defaultFormFactor =
                    state.defaultFormFactor ?? defaultFormFactor;
                  delete state.openingAngle;
                  delete state.sidePositionProgress;
                  delete state.landscapeOrientationProgress;
                  delete state.reversedPositionProgress;
                }),
              tx
                .table("designs")
                .toCollection()
                .modify((design) => {
                  design.formFactor = design.formFactor ?? defaultFormFactor;
                }),
            ]);
          }),
      );

    // In previous versions, a label was often included as part of the
    // form factor values. This messes up with the new form factor comparison
    // algorithm.
    this.version(6)
      .stores({ designs: "id", state: "id" })
      .upgrade((tx) =>
        Dexie.Promise.all([
          tx
            .table("state")
            .toCollection()
            .modify((state) => {
              delete state.defaultFormFactor.label;
            }),
          tx
            .table("designs")
            .toCollection()
            .modify((design) => {
              delete design.formFactor.label;
            }),
        ]),
      );

    this.version(7)
      .stores({ designs: "id", state: "id" })
      .upgrade(async (tx) => {
        let designs: IDesign[] = await tx.table("designs").toArray();
        return Dexie.Promise.all([
          tx
            .table("state")
            .toCollection()
            .modify((state) => {
              state.designOrder = sortBy(designs, "order").map((d) => d.id);
            }),
          tx
            .table("designs")
            .toCollection()
            .modify((design) => {
              delete design.order;
            }),
        ]);
      });

    this.designs = this.table("designs");
    this.state = this.table("state");
  }
}
