/** @jsxImportSource @emotion/react */

import { css, Theme, useTheme } from "@emotion/react";
import { useTransition } from "@react-spring/web";
import { animated } from "@react-spring/web";
import * as React from "react";
import { ValueOf } from "type-fest";

import { useToastStore } from "../stores/toasts";
import { Toast as ToastType } from "../stores/toasts";
import { Toast } from "./Toast";

const mainCss = (theme: Theme) =>
  css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  });

const toastAnimationDiv = (theme: Theme) => ({
  width: "100%",
});

const toastCss = (theme: Theme) =>
  css({ width: "100%", position: "absolute", bottom: 0 });

type ToastId = ValueOf<ToastType, "id">;
export default function ToastContainer(): React.ReactElement {
  const theme = useTheme();
  const [toastHeightMap, setToastHeightMap] = React.useState<
    Record<ToastId, number>
  >({});
  const { toastList, close } = useToastStore();
  const transitions = useTransition(toastList, {
    keys: (toast) => toast.id,
    from: {
      opacity: 0,
      filter: `blur(${theme.blurs[3]}px)`,
      transform: `translate(0, ${theme.space[4]}px)`,
      marginTop: 0,
      height: 0,
    },
    enter: (toast) => ({
      filter: `blur(0px)`,
      opacity: 1,
      marginTop: theme.space[2],
      transform: "translate(0, 0px)",
      height: toastHeightMap[toast.id],
    }),
    update: (toast) => ({ height: toastHeightMap[toast.id] }),
    leave: {
      filter: `blur(${theme.blurs[3]}px)`,
      transform: "translate(0, 0px)",
      marginTop: 0,
      opacity: 0,
      height: 0,
    },
    onRest: (result, controller, toast) => {
      if (toastList.every((t) => t.id !== toast.id)) {
        let { [toast.id]: h, ...others } = toastHeightMap;
        setToastHeightMap(others);
      }
    },
  });
  return (
    <div css={mainCss(theme)}>
      {transitions((style, { message, id, ...props }) => (
        <animated.div css={toastAnimationDiv(theme)} style={style}>
          <div
            css={toastCss(theme)}
            ref={(elt) => {
              if (elt == null) return;
              let height = elt.getBoundingClientRect().height;
              if (toastHeightMap[id] === height) return;
              setToastHeightMap({
                ...toastHeightMap,
                [id]: elt.getBoundingClientRect().height,
              });
            }}
          >
            <Toast {...props} id={id} onClose={close}>
              {message}
            </Toast>
          </div>
        </animated.div>
      ))}
    </div>
  );
}
