import { Theme } from "@emotion/react";

declare module "@emotion/react" {
  export interface Theme {
    space: number[];
    sizes: number[];
    fontSizes: FontSizes;
    transitions: string[];
    borderRadiuses: number[];
    blurs: number[];
    colors: {
      negative: string[];
      positive: string[];
      accent: string[];
      error: string;
      success: string;
      transparencySuffixes: string[];
    };
  }
}

interface FontSizes extends Array<number> {
  body: number;
}

const theme: Theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: [0, 16, 32, 64, 128, 256, 512, 1024],
  fontSizes: [12, 14, 16, 20, 24, 32] as FontSizes,
  transitions: ["0ms", "200ms", "400ms", "800ms", "1600ms"],
  borderRadiuses: [0, 4, 8],
  blurs: [0, 4, 8, 16, 32, 64],
  colors: {
    negative: ["#eceff4", "#e5e9f0", "#d8dee9"],
    positive: ["#2e3440", "#3b4252", "#434c5e", "#4c566a"],
    accent: ["#5E81AC", "#81A1C1", "#88C0D0", "#8FBCBB"],
    error: "#BF616A",
    success: "#A3BE8C",
    transparencySuffixes: ["AA", "77"],
  },
};
theme.fontSizes.body = theme.fontSizes[2];

export default theme;
