import styled from "@emotion/styled";
import isEqualWith from "lodash/isEqualWith";
import * as React from "react";
import shallow from "zustand/shallow";

import { FORM_FACTORS } from "../lib/form-factors";
import { approxEqual } from "../lib/math";
import useMainStore, { MainStore } from "../stores/main";
import { ComputerFormFactor } from "../stores/main/types";

type FormFactorButtonProps = { isCurrent: boolean; isLookedUp: boolean };
const FormFactorButton = styled.button<FormFactorButtonProps>(
  ({ isCurrent, isLookedUp, theme }) => ({
    transition: theme.transitions[1],
    padding: `${theme.space[2]}px ${theme.space[3]}px`,
    cursor: "pointer",
    width: theme.sizes[4],
    borderRadius: theme.borderRadiuses[1],
    background: isCurrent
      ? theme.colors.accent[0]
      : isLookedUp
      ? theme.colors.positive[3]
      : theme.colors.positive[0],
    pointerEvents: "auto",
    border: "none",
    // lineHeight 1 makes  the size of the button more predictable.
    lineHeight: 1,
    // We use half space margins to "simulate" margin collapsing.
    margin: theme.space[2] / 2,
    "&:hover": {
      background: isCurrent ? undefined : theme.colors.positive[3],
    },
    "&:active": {
      background: theme.colors.accent[0],
      transition: theme.transitions[0],
    },
    "@supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)))":
      {
        background: isCurrent
          ? `${theme.colors.accent[0]}${theme.colors.transparencySuffixes[0]}`
          : isLookedUp
          ? `${theme.colors.positive[3]}${theme.colors.transparencySuffixes[0]}`
          : `${theme.colors.positive[0]}${theme.colors.transparencySuffixes[0]}`,
        backdropFilter: `blur(10px)`,
        WebkitBackdropFilter: `blur(10px)`,
        "&:hover": {
          background: isCurrent
            ? undefined
            : `${theme.colors.positive[3]}${theme.colors.transparencySuffixes[0]}`,
        },
        "&:active": {
          background: `${theme.colors.accent[0]}${theme.colors.transparencySuffixes[0]}`,
        },
      },
    color: theme.colors.negative[0],
  }),
);

const MainDiv = styled.div(({ theme }) => ({
  // Add missing margin from buttons.
  margin: theme.space[2] / 2,
}));

export default function FormFactorSelect(): React.ReactElement {
  const {
    setDesignFormFactor,
    setDefaultFormFactor,
    currentFormFactor,
    currentDesignId,
    lookedUpFormFactor,
  } = useMainStore(paramSelector, shallow);

  function setFormFactor(formFactor: ComputerFormFactor) {
    if (currentDesignId != null) {
      setDesignFormFactor(currentDesignId, formFactor);
    } else {
      setDefaultFormFactor(formFactor);
    }
  }

  return (
    <MainDiv>
      {Object.entries(FORM_FACTORS).map(
        ([id, { label, values: formFactor }]) => (
          <FormFactorButton
            role="button"
            isLookedUp={areObjsApproxEqual(lookedUpFormFactor, formFactor)}
            isCurrent={areObjsApproxEqual(currentFormFactor, formFactor)}
            key={id}
            onClick={() => {
              setFormFactor(formFactor);
            }}
          >
            {label}
          </FormFactorButton>
        ),
      )}
    </MainDiv>
  );
}

function areObjsApproxEqual(obj1: unknown, obj2: unknown) {
  return isEqualWith(obj1, obj2, (v1, v2) => {
    if (typeof v1 === "number" && typeof v2 === "number") {
      return approxEqual(v1, v2);
    }
    return undefined;
  });
}

const paramSelector = (store: MainStore) => {
  let currentDesign = store.getDesign(store.currentDesignId);
  let lookedUpDesign = store.getDesign(store.lookedUpDesignId);
  let formFactor = currentDesign?.formFactor ?? store.defaultFormFactor;
  return {
    currentFormFactor: formFactor,
    lookedUpFormFactor: lookedUpDesign?.formFactor ?? null,
    currentDesignId: store.currentDesignId,
    setDesignFormFactor: store.setDesignFormFactor,
    setDefaultFormFactor: store.setDefaultFormFactor,
  };
};
