import styled from "@emotion/styled";
import * as React from "react";
import { useCallback } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import shallow from "zustand/shallow";

import useArrowKeys from "../../hooks/useArrowKeys";
import useUniqueId from "../../hooks/useUniqueId";
import useMainStore, { MainStore } from "../../stores/main";
import AddDesignButton from "./AddDesignButton";
import BaseDesign from "./Design";

const Design = React.memo(BaseDesign);

const MainDiv = styled.div({
  display: `flex`,
  flexDirection: `row`,
  alignItems: `flex-end`,
  color: `inherit`,
});

const DesignDropDiv = styled.div({
  display: `flex`,
  color: `inherit`,
});

const DesignWrapper = styled.div({
  minHeight: "100%",
  display: `flex`,
  flexDirection: `row`,
  alignItems: `flex-end`,
});

const selector = (store: MainStore) => ({
  designOrder: store.designOrder,
  // We only really need the ids of current design and looked up design, albeit
  // it won't prevent any render in theory since we also fetch store.designs.
  currentDesignId: store.currentDesignId,
  lookedUpDesignId: store.lookedUpDesignId,
  setCurrentDesign: store.setCurrentDesign,
  setLookedUpDesign: store.setLookedUpDesign,
  removeDesign: store.removeDesign,
  reorderDesigns: store.reorderDesigns,
});

export default function DesignSelect(): React.ReactElement {
  const {
    designOrder,
    currentDesignId,
    lookedUpDesignId,
    setCurrentDesign,
    setLookedUpDesign,
    removeDesign,
    reorderDesigns,
  } = useMainStore(selector, shallow);

  const droppableId = useUniqueId();

  useArrowKeys({
    right: () => {
      if (currentDesignId == null) return;
      let currentIdx = designOrder.findIndex(
        (designId) => designId === currentDesignId,
      );
      if (currentIdx + 1 < designOrder.length) {
        setCurrentDesign(designOrder[currentIdx + 1]);
      }
    },
    left: () => {
      if (currentDesignId == null) return;
      let currentIdx = designOrder.findIndex(
        (designId) => designId === currentDesignId,
      );
      if (currentIdx > 0) {
        setCurrentDesign(designOrder[currentIdx - 1]);
      }
    },
  });

  const handleLookUpStart = useCallback(
    (designId) => {
      if (lookedUpDesignId !== designId) {
        setLookedUpDesign(designId);
      }
    },
    [lookedUpDesignId, setLookedUpDesign],
  );

  const handleLookUpEnd = useCallback(
    (designId) => {
      if (lookedUpDesignId === designId) {
        setLookedUpDesign(null);
      }
    },
    [lookedUpDesignId, setLookedUpDesign],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination != null) {
        reorderDesigns(result.draggableId, result.destination.index);
      }
    },
    [reorderDesigns],
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={droppableId} direction="horizontal">
        {(provided, snapshot) => (
          <MainDiv role="menu">
            <DesignDropDiv ref={provided.innerRef} {...provided.droppableProps}>
              {designOrder.map((designId, index) => (
                <Draggable key={designId} draggableId={designId} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                    >
                      <DesignWrapper>
                        <Design
                          id={designId}
                          isDragging={snapshot.isDragging}
                          isDraggingOut={
                            snapshot.isDragging && snapshot.draggingOver == null
                          }
                          isSelected={currentDesignId === designId}
                          isLookedUp={lookedUpDesignId === designId}
                          onRemove={removeDesign}
                          onSelect={setCurrentDesign}
                          onLookUpStart={handleLookUpStart}
                          onLookUpEnd={handleLookUpEnd}
                        />
                      </DesignWrapper>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </DesignDropDiv>
            <AddDesignButton />
          </MainDiv>
        )}
      </Droppable>
    </DragDropContext>
  );
}
