/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";

export type MenuItemIconProps = {
  size: number;
};

export type MenuItemContentProps = {
  label: string;
  iconComponent?: React.ComponentType<MenuItemIconProps>;
};

export default function MenuItemContent({
  label,
  iconComponent: Icon,
}: MenuItemContentProps): React.ReactElement {
  const theme = useTheme();
  return (
    <>
      {Icon != null && (
        <span
          css={{
            verticalAlign: "middle",
            marginRight: theme.space[2],
            display: "inline-block",
          }}
        >
          <Icon size={theme.sizes[1]} />
        </span>
      )}
      <span>{label}</span>
    </>
  );
}
