import * as React from "react";

type UseArrowsParameter = {
  left?: (direction: "left", evt: KeyboardEvent) => void;
  right?: (direction: "right", evt: KeyboardEvent) => void;
  up?: (direction: "up", evt: KeyboardEvent) => void;
  down?: (direction: "down", evt: KeyboardEvent) => void;
};

export default function useArrowKeys({
  left,
  right,
  up,
  down,
}: UseArrowsParameter): void {
  React.useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      switch (evt.key) {
        case "ArrowRight":
          right && right("right", evt);
          break;
        case "ArrowLeft":
          left && left("left", evt);
          break;
        case "ArrowDown":
          down && down("down", evt);
          break;
        case "ArrowUp":
          up && up("up", evt);
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [left, right, up, down]);
}
