import { ComputerFormFactor } from "../stores/main/types";

export type FormFactorId =
  | "closed"
  | "laptop"
  | "picture"
  | "book"
  | "tablet"
  | "tent"
  | "display"
  | "halfTablet";

type NamedFormFactor = { label: string; values: ComputerFormFactor };

export const FORM_FACTORS: Record<FormFactorId, NamedFormFactor> = {
  closed: {
    values: {
      openingAngle: 0,
      sidePositionProgress: 0,
      landscapeOrientationProgress: 0,
      reversedPositionProgress: 0,
    },
    label: "closed",
  },
  laptop: {
    values: {
      openingAngle: 100,
      sidePositionProgress: 0,
      landscapeOrientationProgress: 0,
      reversedPositionProgress: 0,
    },
    label: "laptop",
  },
  picture: {
    values: {
      openingAngle: 120,
      sidePositionProgress: 1,
      landscapeOrientationProgress: 1,
      reversedPositionProgress: 0,
    },
    label: "picture",
  },
  book: {
    values: {
      openingAngle: 180,
      sidePositionProgress: 0,
      landscapeOrientationProgress: 1,
      reversedPositionProgress: 0,
    },
    label: "book",
  },
  tablet: {
    values: {
      openingAngle: 180,
      sidePositionProgress: 0,
      landscapeOrientationProgress: 0,
      reversedPositionProgress: 0,
    },
    label: "tablet",
  },
  tent: {
    values: {
      openingAngle: 320,
      sidePositionProgress: 0,
      landscapeOrientationProgress: 0,
      reversedPositionProgress: 0,
    },
    label: "tent",
  },
  display: {
    values: {
      openingAngle: 280,
      sidePositionProgress: 0,
      landscapeOrientationProgress: 0,
      reversedPositionProgress: 1,
    },
    label: "display",
  },
  halfTablet: {
    values: {
      openingAngle: 360,
      sidePositionProgress: 0,
      landscapeOrientationProgress: 0,
      reversedPositionProgress: 1,
    },
    label: "half tablet",
  },
};
