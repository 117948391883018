import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const dotAnimation = keyframes({
  "0%, 100%": {
    opacity: 1,
  },
  "50%": { opacity: 0.3 },
});

const LoadingDiv = styled.div(({ theme }) => ({
  position: "relative",
  width: theme.sizes[2] * 3 + theme.space[2] * 2,
  height: theme.sizes[2] * 3 + theme.space[2] * 2,
  div: {
    position: "absolute",
    width: theme.sizes[2],
    height: theme.sizes[2],
    borderRadius: "50%",
    background: "#ddd",
    animation: `${dotAnimation} 1.2s linear infinite`,
  },
  "div:nth-of-type(1)": {
    top: 0,
    left: 0,
    animationDelay: "0s",
  },
  "div:nth-of-type(2)": {
    top: 0,
    left: theme.sizes[2] + theme.space[2],
    animationDelay: "-0.4s",
  },
  "div:nth-of-type(3)": {
    top: 0,
    left: (theme.sizes[2] + theme.space[2]) * 2,
    animationDelay: "-0.8s",
  },
  "div:nth-of-type(4)": {
    top: theme.sizes[2] + theme.space[2],
    left: 0,
    animationDelay: "-0.4s",
  },
  "div:nth-of-type(5)": {
    top: theme.sizes[2] + theme.space[2],
    left: theme.sizes[2] + theme.space[2],
    animationDelay: "-0.8s",
  },
  "div:nth-of-type(6)": {
    top: theme.sizes[2] + theme.space[2],
    left: (theme.sizes[2] + theme.space[2]) * 2,
    animationDelay: "-1.2s",
  },
  "div:nth-of-type(7)": {
    top: (theme.sizes[2] + theme.space[2]) * 2,
    left: 0,
    animationDelay: "-0.8s",
  },
  "div:nth-of-type(8)": {
    top: (theme.sizes[2] + theme.space[2]) * 2,
    left: theme.sizes[2] + theme.space[2],
    animationDelay: "-1.2s",
  },
  "div:nth-of-type(9)": {
    top: (theme.sizes[2] + theme.space[2]) * 2,
    left: (theme.sizes[2] + theme.space[2]) * 2,
    animationDelay: "-1.6s",
  },
}));

const fadeInAnimation = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const Main = styled.div(({ theme }) => ({
  animation: `${fadeInAnimation} 500ms forwards`,
  opacity: 0,
  animationDelay: "500ms",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function Loading(): React.ReactElement {
  return (
    <Main>
      <LoadingDiv>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </LoadingDiv>
    </Main>
  );
}
