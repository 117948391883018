import { throttle as rafThrottle } from "frame-throttle";
import {
  AmbientLight,
  AxesHelper,
  Box3,
  Color,
  MathUtils,
  Matrix4,
  Mesh,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Object3D,
  PCFSoftShadowMap,
  PerspectiveCamera,
  PlaneGeometry,
  Renderer,
  RepeatWrapping,
  Scene,
  SpotLight,
  sRGBEncoding,
  Vector3,
  WebGLRenderer,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Except } from "type-fest";

import noOp from "../lib/noOp";
import { ModelRecord } from "../stores/models";
import Computer, {
  ComputerProperties,
  ComputerSettings as BaseComputerSettings,
} from "./Computer";

type BaseSimulatorSettings = {
  useDevicePixelRatio: boolean;
  showAxisHelper: boolean;
  showTable: boolean;
  showLightGuide: boolean;
  constrainControls: boolean;
  groundColor: number;
  showFloor: boolean;
  ambientLightIntensity: number;
  pencilX: number;
  pencilZ: number;
  pencilColor: number;
  mugX: number;
  mugZ: number;
  mugColor: number;
};
type DisplaySettings = {
  initCanvas?: (canvad: HTMLCanvasElement) => void;
  displayResolution: number;
};

type ComputerSettings = Except<BaseComputerSettings, "displayCanvas"> &
  DisplaySettings;

type SimulatorParameters = Partial<BaseSimulatorSettings> & {
  models: ModelRecord;
  computer?: Partial<ComputerSettings>;
};

const DEFAULT_DISPLAY_PX_HEIGHT = 1080 * 2 + 270;
const DEFAULT_DISPLAY_PX_WIDTH = 1920;
const DISPLAY_RESOLUTION = DEFAULT_DISPLAY_PX_WIDTH / 0.35; // px per m
const DEFAULT_HINGE_LENGTH = 0.05;
const DEFAULT_H_BEZELS = 0.005;
const DEFAULT_V_BEZELS = 0.01;
const DEFAULT_SETTINGS: BaseSimulatorSettings & { computer: ComputerSettings } =
  {
    computer: {
      openingAngle: 90,
      sidePositionProgress: 0,
      landscapeOrientationProgress: 0,
      reversedPositionProgress: 0,
      height:
        DEFAULT_DISPLAY_PX_HEIGHT / DISPLAY_RESOLUTION + DEFAULT_V_BEZELS * 2,
      width:
        DEFAULT_DISPLAY_PX_WIDTH / DISPLAY_RESOLUTION + DEFAULT_H_BEZELS * 2,
      thickness: 0.006,
      hingeLength: DEFAULT_HINGE_LENGTH,
      closingGap: 0.002,
      reversedClosingGap: 0.002,
      lowerHingeTransitionAngle: 60,
      upperHingeTransitionAngle: 320,
      topBezel: DEFAULT_V_BEZELS,
      bottomBezel: DEFAULT_V_BEZELS,
      leftBezel: DEFAULT_H_BEZELS,
      rightBezel: DEFAULT_H_BEZELS,
      // This is completely arbitrary, 25 isn't noticeable and shouldn't be too
      // much to handle.
      hingeSegments: 25,
      color: 0x333333,
      displayColor: 0x000000,
      // displayResolution is number of pixels in the recommended template
      // (see DTSL_template.png) divided  by (width - left and top bezels).
      displayResolution: DISPLAY_RESOLUTION,
      isDisplayOn: false,
    },
    groundColor: 0xffffff,
    ambientLightIntensity: 0,
    useDevicePixelRatio: true,
    showAxisHelper: false,
    showTable: true,
    showFloor: true,
    showLightGuide: false,
    constrainControls: true,
    pencilX: -0.35,
    pencilZ: 0.05,
    pencilColor: 0x111111,
    mugX: 0.35,
    mugZ: 0.15,
    mugColor: 0xffffff,
  };

export default class Simulator {
  private renderer: WebGLRenderer;

  private scene: Scene;

  private camera: PerspectiveCamera;

  private table?: Object3D;
  private pencil?: Object3D;
  private mug?: Object3D;
  private computer: Computer;
  private lights: SpotLight[];
  private floor: Object3D;

  private axesHelper: AxesHelper | null = null;

  private controls: OrbitControls;

  private cancelRender: () => void;
  private resizeObserver: ResizeObserver;
  private displayCanvas: HTMLCanvasElement;

  public useDevicePixelRatio: boolean;

  constructor(canvas: HTMLCanvasElement, settingsArg: SimulatorParameters) {
    let settings = {
      ...DEFAULT_SETTINGS,
      ...settingsArg,
      computer: { ...DEFAULT_SETTINGS.computer, ...settingsArg.computer },
    };

    {
      this.displayCanvas = document.createElement("canvas");
      let displayWidth =
        settings.computer.width -
        settings.computer.leftBezel -
        settings.computer.rightBezel;
      let displayHeight =
        settings.computer.height -
        settings.computer.topBezel -
        settings.computer.bottomBezel;
      this.displayCanvas.width =
        displayWidth * settings.computer.displayResolution;
      this.displayCanvas.height =
        displayHeight * settings.computer.displayResolution;
    }

    this.useDevicePixelRatio = settings.useDevicePixelRatio;

    {
      this.renderer = new WebGLRenderer({
        canvas,
        powerPreference: "high-performance",
        antialias: true,
      });
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = PCFSoftShadowMap;
      this.renderer.physicallyCorrectLights = true;
      let throttledRender = rafThrottle(this.render);
      this.render = throttledRender;
      this.cancelRender = throttledRender.cancel;
      this.renderer.outputEncoding = sRGBEncoding;
      this.renderer.gammaFactor = 2.2;
    }

    // Create the scene.
    this.scene = new Scene();
    this.scene.background = new Color(0x000000);

    {
      // Create the camera.
      let fov = 40;
      let aspect = 2; // the canvas default
      let near = 0.1;
      let far = 1000;
      this.camera = new PerspectiveCamera(fov, aspect, near, far);
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this.camera.updateProjectionMatrix();
      this.camera.position.set(0, 0.5, 1);
      this.scene.add(this.camera);
    }

    // Enable rotation around the computer.
    this.controls = new OrbitControls(this.camera, canvas);
    this.controls.target.set(
      0,
      settings.computer.thickness + settings.computer.height / 3,
      0,
    );
    if (settings.constrainControls) {
      this.controls.enablePan = false;
      this.controls.maxPolarAngle = Math.PI / 2 - Math.PI / 20;
      this.controls.minDistance = 0.5;
      this.controls.maxDistance = 3;
    }
    this.controls.update();
    this.controls.addEventListener("change", () => this.render());

    // Create the lights.
    this.lights = [new SpotLight(0xfff4c7), new SpotLight(0xfff4c7)];
    this.lights[0].position.set(0.5, 2, 0.5);
    this.lights[1].position.set(-0.4, 2, 0.45);

    this.lights.forEach((light) => {
      light.intensity = 1.5;
      light.lookAt(0.4, 0.1, 0);
      light.castShadow = true;
      light.penumbra = 0.7;
      light.angle = MathUtils.degToRad(20);
      light.distance = 5;
      light.shadow.mapSize.width = 1024 * 3;
      light.shadow.mapSize.height = 1024 * 3;
      this.scene.add(light);
    });

    if (settings.ambientLightIntensity > 0) {
      this.scene.add(
        new AmbientLight(0xffffff, settings.ambientLightIntensity),
      );
    }

    // tableHeight is also used to set up the position of the floor.
    let tableHeight = new Box3().setFromObject(settings.models.table).max.y;
    if (settings.showTable) {
      // Create the table.
      this.table = settings.models.table.clone();
      // Table position is set on render because for some unknown reason it does
      // not work if initialized here.
      this.scene.add(this.table);
      this.table.traverse((child) => {
        child.castShadow = true;
        child.receiveShadow = true;
      });
      this.table.rotateY(Math.PI / 2);
      this.table.position.y = -tableHeight;
      this.table.position.z = -0.1;
    }

    {
      let floorWidth = 5;
      let floorHeight = 5;
      let floorGeometry = new PlaneGeometry(floorWidth, floorHeight, 1, 1);
      let floorTexture = settings.models.floorTexture;
      floorTexture.wrapS = RepeatWrapping;
      floorTexture.wrapT = RepeatWrapping;
      floorTexture.repeat.x = floorWidth;
      floorTexture.repeat.y = floorHeight;
      floorTexture.needsUpdate = true;

      let floorMaterial = new MeshPhongMaterial({
        color: settings.groundColor,
        reflectivity: 0.8,
        shininess: 150,
        map: settings.models.floorTexture,
      });
      this.floor = new Mesh(floorGeometry, floorMaterial);
      if (settings.showFloor) {
        this.floor.rotation.set(-Math.PI / 2, 0, 0);
        this.floor.position.y = -tableHeight;
        this.floor.receiveShadow = true;
        this.scene.add(this.floor);
      }
    }

    {
      this.pencil = settings.models.pencil.clone();
      let bbox = new Box3().setFromObject(this.pencil);
      let baseHeight = bbox.max.z - bbox.min.z;
      let baseWidth = bbox.max.y - bbox.min.y;
      let targetHeight = 17.5 / 100;
      let factor = targetHeight / baseHeight;
      this.pencil.scale.set(factor, factor, factor);
      this.pencil.position.x = settings.pencilX;
      this.pencil.position.z = settings.pencilZ;
      this.pencil.position.y = (baseWidth * factor) / 2;
      let pencilMaterial = new MeshStandardMaterial({
        color: settings.pencilColor,
        metalness: 0.25,
        roughness: 0.25,
      });
      this.pencil.traverse((child) => {
        if (child instanceof Mesh) {
          child.material = pencilMaterial;
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      this.scene.add(this.pencil);
    }

    {
      this.mug = settings.models.mug.clone();
      let mugMaterial = new MeshStandardMaterial({
        color: settings.mugColor,
        roughness: 0.1,
        metalness: 0,
      });
      this.mug.traverse((child) => {
        if (child instanceof Mesh) {
          child.material = mugMaterial;
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      let tmpMatrix = new Matrix4();
      let tmpVector = new Vector3();
      let transform = new Matrix4();
      let bbox = new Box3().setFromObject(this.mug);
      let y = -bbox.min.y;
      let baseMugHeight = bbox.max.y - bbox.min.y;
      let targetMugHeight = 0.1;
      let scaleFactor = targetMugHeight / baseMugHeight;
      transform.premultiply(tmpMatrix.makeTranslation(0, y, 0));
      transform.premultiply(
        tmpMatrix.makeRotationAxis(tmpVector.set(0, 1, 0), -Math.PI / 3),
      );
      transform.premultiply(
        tmpMatrix.makeScale(scaleFactor, scaleFactor, scaleFactor),
      );
      transform.premultiply(
        tmpMatrix.makeTranslation(settings.mugX, 0, settings.mugZ),
      );

      this.mug.applyMatrix4(transform);
      this.scene.add(this.mug);
    }

    // Create the computer. We are loading display later because the texture
    // needs to be loaded first.
    this.computer = new Computer({
      ...settings.computer,
      displayCanvas: this.displayCanvas,
    });
    this.computer.object3D.traverse((c) => {
      c.castShadow = true;
      c.receiveShadow = true;
    });
    this.scene.add(this.computer.object3D);
    this.updateComputerSettings(settings.computer);
    this.updateComputerDisplay(settings.computer.initCanvas ?? noOp);

    if (settings.showAxisHelper) {
      this.axesHelper = new AxesHelper(5);
      this.scene.add(this.axesHelper);
    }

    this.resizeObserver = new ResizeObserver(() => this.render());
    this.resizeObserver.observe(canvas);

    this.render();
  }

  // This method is throttled in the constructor
  render(): void {
    if (
      resizeRendererToDisplaySize(this.renderer, {
        useDevicePixelRatio: this.useDevicePixelRatio,
      })
    ) {
      const canvas = this.renderer.domElement;
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this.camera.updateProjectionMatrix();
    }

    this.renderer.render(this.scene, this.camera);
  }

  updateComputerSettings(props: Partial<ComputerProperties>): void {
    Object.assign(this.computer, props);
    this.computer.updateGeometry();
    this.render();
  }

  updateComputerDisplay(updater: (canvas: HTMLCanvasElement) => void): void {
    updater(this.displayCanvas);
    this.computer.displayTexture.needsUpdate = true;
    this.render();
  }

  setIsComputerDisplayOn(isComputerDisplayOn: boolean): void {
    this.computer.setIsDisplayOn(isComputerDisplayOn);
    this.render();
  }

  getIsComputerDisplayOn(): boolean {
    return this.computer.getIsDisplayOn();
  }

  /**
   * Stop the simulation. Warning: this should be the last method being called.
   * The behaviour of any method called after this one is undefined.
   */
  stop(): void {
    this.scene.clear();
    this.computer.dispose();
    this.resizeObserver.disconnect();
    this.cancelRender();
  }
}

function resizeRendererToDisplaySize(
  renderer: Renderer,
  { useDevicePixelRatio }: Pick<SimulatorParameters, "useDevicePixelRatio">,
): boolean {
  const pixelRatio = useDevicePixelRatio ? window.devicePixelRatio : 1;
  const canvas = renderer.domElement;
  const width = canvas.clientWidth * pixelRatio;
  const height = canvas.clientHeight * pixelRatio;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}
