export function approxEqual(n1: number, n2: number): boolean {
  return Math.abs(n1 - n2) < Number.EPSILON;
}

export function lerp(
  x: number,
  [minX, maxX]: [number, number],
  [minY, maxY]: [number, number],
  clampFlag = false,
): number {
  let slope = (maxY - minY) / (maxX - minX);
  if (clampFlag) {
    return ((x < minX ? minX : x > maxX ? maxX : x) - minX) * slope + minY;
  } else {
    return (x - minX) * slope + minY;
  }
}
