import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { animated, useSpring } from "@react-spring/web";

type SvgProps = { bandColor?: string } & Omit<
  JSX.IntrinsicElements["svg"],
  "viewBox"
>;

type MenuIconProps = {
  size?: number;
  bandHeight?: number;
  borderRadius?: number;
  icon: "handburger" | "cross";
} & SvgProps;

const Svg = styled.svg<SvgProps>(({ bandColor }) => ({
  display: "block",
  rect: { fill: "currentcolor", stroke: "none" },
}));

export default function AnimatedMenuIcon({
  size,
  bandColor,
  bandHeight,
  borderRadius,
  icon,
  ...svgProps
}: MenuIconProps): React.ReactElement {
  let theme = useTheme();
  size = size ?? theme.space[3];
  bandHeight = bandHeight ?? size / 5;
  bandColor = bandColor ?? theme.colors.negative[0];
  borderRadius = borderRadius ?? bandHeight / 2;
  let interBandSpacing = (size - bandHeight * 3) / 2;

  let middleRotateCenterX = size / 2;
  let middleRotateCenterY = bandHeight + interBandSpacing + bandHeight / 2;

  const spring = useSpring(
    icon === "handburger"
      ? {
          topBottomBandOpacity: 1,
          middle1Transform: `rotate(0, ${middleRotateCenterX}, ${middleRotateCenterY})`,
          middle2Transform: `rotate(0, ${middleRotateCenterX}, ${middleRotateCenterY})`,
        }
      : {
          topBottomBandOpacity: 0,
          middle1Transform: `rotate(45, ${middleRotateCenterX}, ${middleRotateCenterY})`,
          middle2Transform: `rotate(-45, ${middleRotateCenterX}, ${middleRotateCenterY})`,
        },
  );

  return (
    <Svg
      {...svgProps}
      bandColor={bandColor}
      viewBox={`0 0 ${size} ${size}`}
      height={size}
      width={size}
    >
      <animated.rect
        opacity={spring.topBottomBandOpacity}
        x={0}
        y={0}
        width={size}
        height={bandHeight}
        rx={borderRadius}
      />
      <animated.rect
        transform={spring.middle1Transform}
        x={0}
        y={bandHeight + interBandSpacing}
        width={size}
        height={bandHeight}
        rx={borderRadius}
      />
      <animated.rect
        transform={spring.middle2Transform}
        x={0}
        y={bandHeight + interBandSpacing}
        width={size}
        height={bandHeight}
        rx={borderRadius}
      />
      <animated.rect
        opacity={spring.topBottomBandOpacity}
        x={0}
        y={(bandHeight + interBandSpacing) * 2}
        width={size}
        height={bandHeight}
        rx={borderRadius}
      />
    </Svg>
  );
}
