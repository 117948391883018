import { nanoid as uniqueId } from "nanoid";
import { StateCreator } from "zustand";

import { ToastStore } from "./types";

const createToastState: StateCreator<ToastStore> = (set, get, api) => ({
  toastList: [],
  show(message, type) {
    const { toastList } = get();
    let newToastId = uniqueId();
    let newToast = { id: newToastId, message, type };
    set({ toastList: [...toastList, newToast] });
    return newToastId;
  },
  close(toastId: string) {
    const { toastList } = get();
    set({ toastList: toastList.filter((toast) => toast.id !== toastId) });
  },
  closeAll() {
    set({ toastList: [] });
  },
});

export default createToastState;
