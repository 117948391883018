import styled from "@emotion/styled";
import * as React from "react";
import { useCallback } from "react";
import shallow from "zustand/shallow";

import useMainStore from "../../stores/main";

type DesignDivProps = {
  isSelected: boolean;
  isLookedUp: boolean;
  isDragging: boolean;
  isDraggingOut: boolean;
};
const DesignDiv = styled.div<DesignDivProps>(
  ({ isSelected, isLookedUp, isDragging, isDraggingOut, theme }) => ({
    transition: theme.transitions[1],
    boxSizing: `border-box`,
    position: `relative`,
    padding: 0,
    marginRight: theme.space[2],
    borderRadius: theme.borderRadiuses[1],
    backgroundColor: isDraggingOut ? "transparent" : `#fff`,
    pointerEvents: `auto`,

    "&:first-of-type": {
      marginLeft: 0,
    },

    "@supports ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px)))":
      {
        background: isDraggingOut ? "transparent" : `rgba(255, 255, 255, 0.4)`,
        backdropFilter: isDraggingOut ? "" : `blur(${theme.blurs[5]}px)`,
        WebkitBackdropFilter: isDraggingOut ? "" : `blur(${theme.blurs[5]}px)`,
      },

    '[role="button"]': {
      zIndex: 1,
      boxSizing: `border-box`,
      cursor: `pointer`,
      position: `absolute`,
      top: -theme.sizes[1] / 3,
      left: -theme.sizes[1] / 3,
      width: theme.sizes[1],
      height: theme.sizes[1],
      font: `inherit`,
      fontSize: theme.sizes[1] - theme.space[1],
      borderRadius: theme.sizes[1],
      lineHeight: `${theme.sizes[1]}px`,
      textAlign: `center`,
      padding: 0,
      opacity: isLookedUp && !isDragging ? 1 : 0,
      transition: theme.transitions[1],

      color: theme.colors.negative[0],
      background:
        isLookedUp && !isDragging
          ? theme.colors.positive[2]
          : theme.colors.positive[0],
    },

    '[role="menuitem"] img': {
      display: `block`,
      verticalAlign: `middle`,
      position: `relative`,
      cursor: `pointer`,
      borderRadius: theme.borderRadiuses[1],
      filter:
        (isSelected || isLookedUp || isDragging) && !isDraggingOut
          ? "none"
          : "opacity(50%) saturate(30%)",
      transition: theme.transitions[1],
      margin: 0,
      height: `${
        isSelected ? theme.sizes[4] + theme.space[3] : theme.sizes[4]
      }px`,
    },
  }),
);

type DesignProps = {
  isSelected: boolean;
  isLookedUp: boolean;
  isDragging: boolean;
  isDraggingOut: boolean;
  id: string;
  onRemove: (designId: string) => void;
  onSelect: (designId: string) => void;
  onLookUpStart: (designId: string) => void;
  onLookUpEnd: (designId: string) => void;
};

export default function Design({
  id,
  isSelected,
  isLookedUp,
  isDragging,
  isDraggingOut,
  onRemove,
  onSelect,
  onLookUpStart,
  onLookUpEnd,
}: DesignProps): React.ReactElement {
  const { imageUrl, imageAlt } = useMainStore(
    useCallback(
      (store) => {
        let design = store.designs.get(id);
        if (design == null)
          throw new Error(`Cannot render unknown design : ${id}`);
        return { imageUrl: design.image.src, imageAlt: design.fileName };
      },
      [id],
    ),
    shallow,
  );

  function handleRemove() {
    onRemove(id);
  }
  function handleSelect() {
    if (!isDragging) {
      onSelect(id);
    }
  }
  function handleMouseOver() {
    onLookUpStart(id);
  }
  function handleMouseLeave() {
    onLookUpEnd(id);
  }
  return (
    <DesignDiv
      isSelected={isSelected}
      isLookedUp={isLookedUp}
      isDragging={isDragging}
      isDraggingOut={isDraggingOut}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div
        role="button"
        onClick={handleRemove}
        onKeyPress={handleRemove}
        tabIndex={-1}
      >
        &times;
      </div>
      <div
        role="menuitem"
        onClick={handleSelect}
        onKeyPress={handleSelect}
        tabIndex={-1}
      >
        <img src={imageUrl} alt={imageAlt} />
      </div>
    </DesignDiv>
  );
}
