export function readFileAsDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      let url = event.target?.result;
      if (typeof url === "string") {
        resolve(url);
      } else {
        reject(new Error(`Could not load: ${file.name}.`));
      }
    });
    reader.addEventListener("error", () => {
      reject(new Error(`Could not load: ${file.name}.`));
    });
    reader.readAsDataURL(file);
  });
}
