import { LoadingManager, TextureLoader } from "three";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import { ModelRecord } from "./types";

function loadTable() {
  let loadingManager = new LoadingManager();
  let mtlLoader = new MTLLoader(loadingManager);
  return mtlLoader.loadAsync("models/abciuppa_table_w_m_01.mtl").then((mtl) => {
    mtl.preload();
    let objLoader = new OBJLoader(loadingManager);
    objLoader.setMaterials(mtl);
    return Promise.all([
      // MTL loading will resolved even if textures are not ready. This
      // ensures we wait for these textures to be loaded. There might be
      // a very unlikely event where textures might already be loaded, but
      // onload would then be called when the obj loader completes.
      new Promise((resolve, reject) => {
        loadingManager.onLoad = () => resolve(null);
        loadingManager.onError = reject;
      }),
      objLoader.loadAsync("models/abciuppa_table_w_m_01.obj"),
    ]).then(([, table]) => table);
  });
}

export default function loadModels(): Promise<ModelRecord> {
  let textureLoader = new TextureLoader();
  let objLoader = new OBJLoader();
  return Promise.all([
    textureLoader.loadAsync("models/floor.jpg"),
    loadTable(),
    objLoader.loadAsync("models/pencil.obj"),
    objLoader.loadAsync("models/mug.obj"),
  ]).then(([floorTexture, table, pencil, mug]) => {
    return { pencil, table, floorTexture, mug };
  });
}
