/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { ValueOf } from "type-fest";

import useUniqueId from "../../hooks/useUniqueId";
import buttonCSS from "./buttonCSS";
import MenuItemContent, { MenuItemContentProps } from "./MenuItemContent";

export type MenuFileInputItemProps = MenuItemContentProps & {
  onChange: (files: FileList | null) => void;
  accept?: ValueOf<React.InputHTMLAttributes<HTMLInputElement>, "accept">;
};

export default function MenuFileInputItem({
  onChange,
  accept,
  ...contentProps
}: MenuFileInputItemProps): React.ReactElement {
  const id = useUniqueId();
  const theme = useTheme();

  function handleFileChange(event: React.ChangeEvent) {
    let target = event.target as HTMLInputElement;
    onChange(target.files);
  }

  return (
    <label htmlFor={id} css={buttonCSS(theme)}>
      <MenuItemContent {...contentProps} />
      <input
        css={{ display: "none" }}
        type="file"
        id={id}
        accept={accept}
        onChange={handleFileChange}
      />
    </label>
  );
}
