import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import * as React from "react";
import { ValueOf } from "type-fest";

import { Toast as ToastType } from "../stores/toasts/types";
import { Warning as WarningIcon } from "./icons";

type ToastProps = {
  id: ValueOf<ToastType, "id">;
  children: React.ReactChild;
  onClose: (id: ValueOf<ToastType, "id">) => void;
  type: "error";
  duration?: number;
};

const ToastDiv = styled.div(({ theme }) => ({
  background: theme.colors.error,
  color: theme.colors.negative[0],
  padding: `${theme.space[2]}px ${theme.space[3]}px`,
  borderRadius: theme.borderRadiuses[1],
  boxSizing: "border-box",
  width: "100%",
}));

const IconSpan = styled.span(({ theme }) => ({
  verticalAlign: "middle",
  marginRight: theme.space[2],
  display: "inline-block",
}));

export function Toast({
  id,
  children,
  onClose,
  type,
  duration = 5000,
}: ToastProps): React.ReactElement {
  const theme = useTheme();

  React.useEffect(() => {
    let timeoutId = setTimeout(() => onClose(id), duration);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [duration, onClose, id]);

  return (
    <ToastDiv>
      <IconSpan>
        <WarningIcon size={theme.sizes[1]} />
      </IconSpan>
      <span>{children}</span>
    </ToastDiv>
  );
}
