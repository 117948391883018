import { ValueOf } from "type-fest";
import create from "zustand";

import createToastState from "./create";
import type { Toast, ToastStore } from "./types";

export const useToastStore = create(createToastState);

const showSelector = (store: ToastStore) => store.show;
export default function useToast(): ValueOf<ToastStore, "show"> {
  return useToastStore(showSelector);
}
export type { Toast, ToastStore };
