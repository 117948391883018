import {
  BufferGeometry,
  CanvasTexture,
  Color,
  DynamicDrawUsage,
  Float32BufferAttribute,
  Material,
  MathUtils,
  Matrix4,
  Mesh,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Object3D,
  sRGBEncoding,
  Uint32BufferAttribute,
  Vector3,
} from "three";

export type ComputerProperties = {
  width: number;
  height: number;
  hingeLength: number;
  thickness: number;
  openingAngle: number;
  sidePositionProgress: number;
  landscapeOrientationProgress: number;
  reversedPositionProgress: number;
  closingGap: number;
  reversedClosingGap: number;
  lowerHingeTransitionAngle: number;
  upperHingeTransitionAngle: number;
  topBezel: number;
  bottomBezel: number;
  leftBezel: number;
  rightBezel: number;
};

export type ComputerSettings = ComputerProperties & {
  hingeSegments: number;
  color: Color | string | number;
  displayColor: Color | string | number;
  displayCanvas: HTMLCanvasElement;
  isDisplayOn: boolean;
};

type FaceName =
  | "display"
  | "back"
  | "bottom"
  | "front"
  | "left"
  | "right"
  | "bezel";
const GROUP_FACES: Readonly<FaceName[]> = [
  "display",
  "bezel",
  "back",
  "bottom",
  "front",
  "left",
  "right",
];

export default class Computer implements ComputerProperties {
  private boxMaterial: Material;
  private displayMaterial: MeshStandardMaterial;
  private displayColor: Color | string | number;

  private positions: Float32Array;
  private normals: Float32Array;
  private uvs: Float32Array;
  private indices: Uint32Array;

  private totalIndices = 0;
  private totalPositions = 0;

  private hingeSegments: number;

  public openingAngle: number;
  public sidePositionProgress: number;
  public landscapeOrientationProgress: number;
  public reversedPositionProgress: number;

  public width: number;
  public height: number;
  public thickness: number;
  public closingGap: number;
  public reversedClosingGap: number;
  public hingeLength: number;
  public lowerHingeTransitionAngle: number;
  public upperHingeTransitionAngle: number;
  public topBezel: number;
  public bottomBezel: number;
  public leftBezel: number;
  public rightBezel: number;
  public displayTexture: CanvasTexture;
  public object3D: Object3D;

  private isDisplayOn: boolean;
  private mesh: Mesh;

  constructor(settings: ComputerSettings) {
    this.hingeSegments = settings.hingeSegments;

    this.width = settings.width;
    this.height = settings.height;
    this.thickness = settings.thickness;
    this.openingAngle = settings.openingAngle;
    this.sidePositionProgress = settings.sidePositionProgress;
    this.landscapeOrientationProgress = settings.landscapeOrientationProgress;
    this.reversedPositionProgress = settings.reversedPositionProgress;
    this.closingGap = settings.closingGap;
    this.reversedClosingGap = settings.reversedClosingGap;
    this.hingeLength = settings.hingeLength;
    this.lowerHingeTransitionAngle = settings.lowerHingeTransitionAngle;
    this.upperHingeTransitionAngle = settings.upperHingeTransitionAngle;
    this.displayColor = settings.displayColor;
    this.topBezel = settings.topBezel;
    this.bottomBezel = settings.bottomBezel;
    this.leftBezel = settings.leftBezel;
    this.rightBezel = settings.rightBezel;
    this.isDisplayOn = settings.isDisplayOn;
    this.displayTexture = new CanvasTexture(settings.displayCanvas);
    this.displayTexture.encoding = sRGBEncoding;

    let maxIndices = getNumberOfIndices(this.hingeSegments);
    let maxVertices = getNumberOfVertices(this.hingeSegments);

    let geometry = new BufferGeometry();
    let positionAttr = new Float32BufferAttribute(maxVertices * 3, 3);
    let normalAttr = new Float32BufferAttribute(maxVertices * 3, 3);
    let uvAttr = new Float32BufferAttribute(maxVertices * 2, 2);
    let indexAttr = new Uint32BufferAttribute(maxIndices, 1);
    positionAttr.setUsage(DynamicDrawUsage);
    normalAttr.setUsage(DynamicDrawUsage);
    uvAttr.setUsage(DynamicDrawUsage);
    // Index does not need to be dynamic, it shouldn't change much.
    geometry.setAttribute("position", positionAttr);
    geometry.setAttribute("normal", normalAttr);
    geometry.setAttribute("uv", uvAttr);
    geometry.setIndex(indexAttr);

    this.normals = geometry.getAttribute("normal").array as Float32Array;
    this.positions = geometry.getAttribute("position").array as Float32Array;
    this.uvs = geometry.getAttribute("uv").array as Float32Array;
    this.indices = geometry.index?.array as Uint32Array;

    this.boxMaterial = new MeshPhongMaterial({ color: settings.color });
    this.displayMaterial = new MeshStandardMaterial({
      color: this.displayColor,
    });

    this.mesh = new Mesh(
      geometry,
      GROUP_FACES.map((side) =>
        side === "display" ? this.displayMaterial : this.boxMaterial,
      ),
    );
    this.object3D = new Object3D();
    this.object3D.add(this.mesh);
    this.updateGeometry();
    // Initialize the texture.
    this.setIsDisplayOn(this.isDisplayOn);
  }

  public updateGeometry(): void {
    // Reset everything.
    this.totalIndices = 0;
    this.totalPositions = 0;
    this.mesh.geometry.clearGroups();

    this.mesh.geometry.getAttribute("position").needsUpdate = true;
    this.mesh.geometry.getAttribute("normal").needsUpdate = true;
    this.mesh.geometry.getAttribute("uv").needsUpdate = true;

    const { flattenHingeLength, makeHingeTransform } = this.getHingeParams();
    const surfaceLength = (this.height - this.hingeLength) / 2;

    // Using GROUP_SIDES to control the order in which the sides are drawn.
    // This also impacts the order in which the materials need to be set.
    for (let i = 0; i < GROUP_FACES.length; i++) {
      let start = this.totalIndices;
      let face = GROUP_FACES[i];
      switch (face) {
        case "front":
          this.addFront();
          break;
        case "back":
          this.addBack(makeHingeTransform, flattenHingeLength / 2);
          break;
        case "bottom":
        case "left":
        case "right":
        case "display":
          this.addCurvedFace(face, makeHingeTransform, flattenHingeLength);
          break;
        case "bezel":
          this.addBezel(makeHingeTransform, flattenHingeLength / 2);
          break;
        default:
          throw new Error(`Unsupported face: ${face}`);
      }
      this.mesh.geometry.addGroup(start, this.totalIndices, i);
    }

    // Update the draw range, though in theory it should never change.
    let indexAttribute = this.mesh.geometry.index;
    if (
      indexAttribute != null &&
      indexAttribute.itemSize !== this.totalIndices
    ) {
      this.mesh.geometry.setDrawRange(0, this.totalIndices);
      indexAttribute.itemSize = this.totalIndices;
      indexAttribute.needsUpdate = true;
    }

    // Set up the rotation round the x axis in function of the opening.
    // When the hinge is reversed (openingAngle > 180), the laptop should rotate
    // So the face B does not go through its support.
    let meshTransform = new Matrix4();
    let tmpMatrix = new Matrix4();
    let tmpVector = new Vector3();
    let tmpVectorBis = new Vector3();
    let tmpVectorTer = new Vector3();
    let fullHingeTransform = makeHingeTransform(1);

    let factor =
      this.openingAngle < 180
        ? Math.max(
            this.landscapeOrientationProgress,
            this.reversedPositionProgress,
          )
        : 1 - this.reversedPositionProgress;
    meshTransform.premultiply(
      tmpMatrix.makeRotationAxis(
        tmpVector.set(1, 0, 0),
        factor * MathUtils.degToRad((this.openingAngle - 180) / 2),
      ),
    );

    // Rotate the laptop in reversed position (if needed).
    meshTransform.premultiply(
      tmpMatrix.makeRotationAxis(
        tmpVector.set(1, 0, 0),
        this.reversedPositionProgress * Math.PI,
      ),
    );

    // Rotate the laptop in side position (if needed).
    meshTransform.premultiply(
      tmpMatrix.makeRotationAxis(
        tmpVector.set(0, 0, -1),
        (this.sidePositionProgress * -Math.PI) / 2,
      ),
    );

    // Rotate the laptop in landscape orientation (if needed).
    meshTransform.premultiply(
      tmpMatrix.makeRotationAxis(
        tmpVector.set(0, 1, 0),
        this.landscapeOrientationProgress * (Math.PI / 2),
      ),
    );

    // Recenter the middle point of the laptop (we will worry later about the
    // laptop going through its support). A different reference point is used
    // when the laptop is on the side.
    let middleHingeRefProgress = Math.max(
      this.sidePositionProgress,
      this.landscapeOrientationProgress,
    );
    // Origin in laptop position.
    tmpVector
      .set(this.width / 2, this.thickness, -surfaceLength)
      .applyMatrix4(meshTransform);
    // Origin in side position.
    tmpVectorBis
      .set(
        this.width / 2,
        this.thickness,
        -surfaceLength - flattenHingeLength / 2,
      )
      .applyMatrix4(makeHingeTransform(0.5, tmpMatrix))
      .applyMatrix4(meshTransform);
    meshTransform.premultiply(
      tmpMatrix.makeTranslation(
        -tmpVector.x * (1 - middleHingeRefProgress) -
          tmpVectorBis.x * middleHingeRefProgress,
        -tmpVector.y * (1 - middleHingeRefProgress) -
          tmpVectorBis.y * middleHingeRefProgress,
        -tmpVector.z * (1 - middleHingeRefProgress) -
          tmpVectorBis.z * middleHingeRefProgress,
      ),
    );

    // Make sure the laptop don't go through its support. To do so, we check
    // the points on the two surfaces and look for the lowest negative y.
    let minY = 0;
    [0, this.width].forEach((x) => {
      [0, this.thickness].forEach((y) => {
        // Face C.
        [0, -surfaceLength].forEach((z) => {
          tmpVector.set(x, y, z).applyMatrix4(meshTransform);
          if (tmpVector.y < minY) {
            minY = tmpVector.y;
          }
        });
        // Face B.
        [-surfaceLength, -2 * surfaceLength].forEach((z) => {
          tmpVector
            .set(x, y, z)
            .applyMatrix4(
              tmpMatrix.copy(fullHingeTransform).premultiply(meshTransform),
            );
          if (tmpVector.y < minY) {
            minY = tmpVector.y;
          }
        });
      });
    });

    // We do the same thing for the points on the left hinge.
    tmpVector.set(0, 0, -surfaceLength - flattenHingeLength / 2);
    tmpVectorBis.set(
      0,
      this.thickness,
      -surfaceLength - flattenHingeLength / 2,
    );
    // We create the minimal rotation transform, and apply it successively.
    // To the same vector.
    makeHingeTransform(1 / this.hingeSegments, tmpMatrix);
    for (let i = 0; i < this.hingeSegments; i++) {
      tmpVector.applyMatrix4(tmpMatrix);
      tmpVectorTer.copy(tmpVector).applyMatrix4(meshTransform);
      if (tmpVectorTer.y < minY) {
        minY = tmpVectorTer.y;
      }
      tmpVectorBis.applyMatrix4(tmpMatrix);
      tmpVectorTer.copy(tmpVectorBis).applyMatrix4(meshTransform);
      if (tmpVectorTer.y < minY) {
        minY = tmpVectorTer.y;
      }
    }

    if (minY < 0) {
      meshTransform.premultiply(tmpMatrix.makeTranslation(0, -minY, 0));
    }

    this.mesh.applyMatrix4(
      // Transformation matrices are applied on top of each other on object 3D.
      // As a result we revert the current transform matrix before applying the
      // new.
      tmpMatrix.copy(this.mesh.matrix).invert().premultiply(meshTransform),
    );
  }

  private getHingeParams() {
    const surfaceLength = (this.height - this.hingeLength) / 2;

    // When opening angle is 180, the hinge radius becomes infinite, and
    // the usual transformation is broken. Instead, we switch to simple
    // translations.
    if (this.openingAngle === 180) {
      return {
        makeHingeTransform: (progress: number, matrix = new Matrix4()) =>
          matrix.makeTranslation(0, 0, -this.hingeLength * progress),
        flattenHingeLength: 0,
      };
    }

    const radOpening = MathUtils.degToRad(this.openingAngle);

    // This measure the part of the hinge that is flat, by default it is 0.
    let flattenHingeHalfLength = 0;
    let hingeRadius = Math.abs(this.hingeLength / (Math.PI - radOpening));

    // Case hinge length will make the gap between the two surfaces larger than
    // it should when the laptop is closed. In this case, part of the hinge
    // will be flat and merged with the surface.
    if (
      this.openingAngle < this.lowerHingeTransitionAngle &&
      this.closingGap < (this.hingeLength * 2) / Math.PI
    ) {
      let radHingeTransitionAngle = MathUtils.degToRad(
        this.lowerHingeTransitionAngle,
      );
      // Circum = radius * angle
      let finalRoundHingeLength = (this.closingGap / 2) * Math.PI;
      let finalFlatHingeLength = this.hingeLength - finalRoundHingeLength;
      let transitionProgress =
        (radHingeTransitionAngle - radOpening) / radHingeTransitionAngle;
      flattenHingeHalfLength = (finalFlatHingeLength * transitionProgress) / 2;
      hingeRadius =
        (this.hingeLength - flattenHingeHalfLength * 2) /
        (Math.PI - radOpening);
    } else if (
      this.openingAngle > this.upperHingeTransitionAngle &&
      this.reversedClosingGap < (this.hingeLength / Math.PI) * 2
    ) {
      let radHingeTransitionAngle = MathUtils.degToRad(
        this.upperHingeTransitionAngle,
      );
      let finalRoundHingeLength =
        (this.reversedClosingGap / 2 + this.thickness) * Math.PI;
      let finalFlatHingeLength = this.hingeLength - finalRoundHingeLength;
      let transitionProgress =
        (radOpening - radHingeTransitionAngle) /
        (2 * Math.PI - radHingeTransitionAngle);
      flattenHingeHalfLength = (finalFlatHingeLength * transitionProgress) / 2;
      hingeRadius =
        (this.hingeLength - flattenHingeHalfLength * 2) /
        (radOpening - Math.PI);
    }

    let hingePosition = new Vector3(
      0,
      this.openingAngle < 180
        ? this.thickness + hingeRadius
        : // Hinge radius is always calculated from the top, so even in reverse
          // hinge condition we need to add the thickness.
          this.thickness - hingeRadius,
      -(surfaceLength + flattenHingeHalfLength),
    );

    const hingeAxis = new Vector3(1, 0, 0);
    const fromHingeCoordTransform = new Matrix4().makeTranslation(
      ...hingePosition.toArray(),
    );
    const toHingeCoordTransform = fromHingeCoordTransform.clone().invert();
    const makeHingeTransform = (progress: number, matrix = new Matrix4()) =>
      matrix
        .makeRotationAxis(hingeAxis, (Math.PI - radOpening) * progress)
        // Using this weird combination of premultipy and multiply to avoid
        // instanciating an additionnal matrix.
        .premultiply(fromHingeCoordTransform)
        .multiply(toHingeCoordTransform);

    return {
      makeHingeTransform,
      flattenHingeLength: flattenHingeHalfLength * 2,
    };
  }

  private addFront() {
    let normal = new Vector3(0, 0, 1);
    this.addQuad({
      topLeft: {
        position: new Vector3(0, this.thickness, 0),
        normal,
        uv: [0, 1],
      },
      topRight: {
        position: new Vector3(this.width, this.thickness, 0),
        normal,
        uv: [1, 1],
      },
      bottomRight: {
        position: new Vector3(this.width, 0, 0),
        normal,
        uv: [1, 0],
      },
      bottomLeft: { position: new Vector3(0, 0, 0), normal, uv: [0, 0] },
    });
  }

  private addBack(
    makeHingeTransform: (progress: number) => Matrix4,
    flattenHingeHalfLength: number,
  ) {
    const surfaceLength = (this.height - this.hingeLength) / 2;

    // The quad is created as square of side 1, the first part of the
    // transformation is always to rescale it.
    let transform = new Matrix4()
      .makeScale(
        this.width,
        this.thickness,
        -2 * (surfaceLength + flattenHingeHalfLength),
      )
      // Rotate
      .premultiply(makeHingeTransform(1));
    let normal = new Vector3(0, 0, 1).transformDirection(transform);
    this.addQuad({
      bottomLeft: {
        position: new Vector3(0, 1, 1).applyMatrix4(transform),
        normal,
        uv: [0, 1],
      },
      bottomRight: {
        position: new Vector3(1, 1, 1).applyMatrix4(transform),
        normal,
        uv: [1, 1],
      },
      topRight: {
        position: new Vector3(1, 0, 1).applyMatrix4(transform),
        normal,
        uv: [1, 0],
      },
      topLeft: {
        position: new Vector3(0, 0, 1).applyMatrix4(transform),
        normal,
        uv: [0, 0],
      },
    });
  }

  private addCurvedFace(
    face: "left" | "right" | "bottom" | "display",
    makeHingeTransform: (progress: number, matrix?: Matrix4) => Matrix4,
    flattenHingeLength: number,
  ) {
    const surfaceLength = (this.height - this.hingeLength) / 2;

    const hingeSegmentLength =
      (this.hingeLength - flattenHingeLength) / this.hingeSegments;

    let tmpTransform = new Matrix4();
    let tmpMatrix = new Matrix4();
    let tmpVector = new Vector3();

    // The values below specifies the parts of the 1x1 plane that are filled.
    // Below defines values to completely fill it in. Note that right is right
    // when looking at the laptop from the left side.
    let right = 0;
    let left = 1;
    let bottom = 0;
    let top = 1;
    let totalLength = this.height;
    let startSurfaceLength = surfaceLength;

    // Vertices are initially created on a 1x1 plane on the left. These
    // transforms put them back on the proper face, before the hinge
    // transformation happens (if needed).
    const faceTransform = new Matrix4();
    if (face === "right") {
      faceTransform
        .makeRotationAxis(new Vector3(0, 0, 1), Math.PI)
        .premultiply(tmpMatrix.makeTranslation(1, 1, 0));
    } else if (face === "bottom") {
      faceTransform
        .makeRotationAxis(new Vector3(0, 0, 1), Math.PI / 2)
        .premultiply(tmpMatrix.makeTranslation(1, 0, 0));
    } else if (face === "display") {
      faceTransform
        .makeRotationAxis(new Vector3(0, 0, 1), -Math.PI / 2)
        .premultiply(tmpMatrix.makeTranslation(0, 1, 0));
      right = this.bottomBezel / this.height;
      left = 1 - this.topBezel / this.height;
      bottom = this.leftBezel / this.width;
      top = 1 - this.rightBezel / this.width;
      totalLength = this.height - this.topBezel - this.bottomBezel;
      startSurfaceLength = surfaceLength - this.bottomBezel;
    }
    faceTransform.premultiply(
      tmpMatrix.makeScale(this.width, this.thickness, this.height),
    );

    const normal = new Vector3(-1, 0, 0).transformDirection(faceTransform);

    function uv(x0: number, y0: number): [number, number] {
      return [x0, y0];
    }

    let topRightIdx = this.pushVertex(
      tmpVector.set(0, top, -right).applyMatrix4(faceTransform),
      normal,
      uv(1, 0),
    );
    let bottomRightIdx = this.pushVertex(
      tmpVector.set(0, bottom, -right).applyMatrix4(faceTransform),
      normal,
      uv(0, 0),
    );
    let zProgress = (surfaceLength + flattenHingeLength / 2) / this.height;
    // This loop should create hingeSegments + 1 quads, because it also creates
    // face C's quad.
    for (let i = 0; i < this.hingeSegments + 1; i++) {
      let progress =
        (startSurfaceLength + flattenHingeLength / 2 + hingeSegmentLength * i) /
        totalLength;
      tmpTransform
        .makeTranslation(0, 0, -zProgress)
        .premultiply(faceTransform)
        .premultiply(makeHingeTransform(i / this.hingeSegments, tmpMatrix));
      normal.set(-1, 0, 0).transformDirection(tmpTransform);
      let quadIndices = this.addQuad({
        topRight: topRightIdx,
        bottomRight: bottomRightIdx,
        topLeft: {
          normal,
          uv: uv(1, progress),
          position: new Vector3(0, top, 0).applyMatrix4(tmpTransform),
        },
        bottomLeft: {
          normal,
          uv: uv(0, progress),
          position: new Vector3(0, bottom, 0).applyMatrix4(tmpTransform),
        },
      });
      topRightIdx = quadIndices.topLeft;
      bottomRightIdx = quadIndices.bottomLeft;
    }
    tmpTransform
      // The unit position is not 1 because part of the transformation comes
      // from the hinge transform.
      .makeTranslation(0, 0, -2 * zProgress)
      .premultiply(faceTransform)
      .premultiply(makeHingeTransform(1, tmpMatrix));
    normal.set(-1, 0, 0).transformDirection(tmpTransform);
    this.addQuad({
      topRight: topRightIdx,
      bottomRight: bottomRightIdx,
      topLeft: {
        normal,
        uv: uv(1, 1),
        position: new Vector3(0, top, 1 - left).applyMatrix4(tmpTransform),
      },
      bottomLeft: {
        normal,
        uv: uv(0, 1),
        position: new Vector3(0, bottom, 1 - left).applyMatrix4(tmpTransform),
      },
    });
  }

  private addBezel(
    makeHingeTransform: (progress: number, matrix?: Matrix4) => Matrix4,
    flattenHingeHlfLength: number,
  ) {
    const surfaceLength = (this.height - this.hingeLength) / 2;
    const hingeSegmentLength =
      (this.hingeLength - flattenHingeHlfLength * 2) / this.hingeSegments;

    const baseTransform = new Matrix4().makeTranslation(0, this.thickness, 0);
    let baseNormal = new Vector3(0, 1, 0);

    const createBezelVertex = (
      x: number,
      y: number,
      z: number,
      transform: Matrix4 | null = null,
      uvXInc = 0,
    ): Vertex => {
      let position = new Vector3(x, y, z).applyMatrix4(baseTransform);
      let normal = baseNormal;
      if (transform != null) {
        position.applyMatrix4(transform);
        normal = normal.clone().transformDirection(transform);
      }
      return {
        normal,
        position,
        uv: [(x + uvXInc) / this.width, 1 + z / this.height],
      };
    };

    let tmpMatrix = new Matrix4();

    // Bottom left corner.
    let prevQuad = this.addQuad({
      bottomLeft: createBezelVertex(0, 0, 0),
      topLeft: createBezelVertex(0, 0, -this.bottomBezel),
      topRight: createBezelVertex(this.leftBezel, 0, -this.bottomBezel),
      bottomRight: createBezelVertex(this.leftBezel, 0, 0),
    });
    let startLeft = prevQuad.topLeft;
    let startRight = prevQuad.topRight;

    // Bottom.
    prevQuad = this.addQuad({
      topLeft: prevQuad.topRight,
      bottomLeft: prevQuad.bottomRight,
      topRight: createBezelVertex(
        this.width - this.rightBezel,
        0,
        -this.bottomBezel,
      ),
      bottomRight: createBezelVertex(this.width - this.rightBezel, 0, 0),
    });

    // Bottom right corner.
    prevQuad = this.addQuad({
      topLeft: prevQuad.topRight,
      bottomLeft: prevQuad.bottomRight,
      topRight: createBezelVertex(this.width, 0, -this.bottomBezel),
      bottomRight: createBezelVertex(this.width, 0, 0),
    });

    // Bottom right side.
    prevQuad = this.addQuad({
      bottomRight: prevQuad.topRight,
      bottomLeft: prevQuad.topLeft,
      topRight: createBezelVertex(
        this.width,
        0,
        -surfaceLength - flattenHingeHlfLength,
      ),
      topLeft: createBezelVertex(
        this.width - this.rightBezel,
        0,
        -surfaceLength - flattenHingeHlfLength,
      ),
    });

    // Right Hinge.
    for (let i = 0; i < this.hingeSegments; i++) {
      let progress = (1 / this.hingeSegments) * (i + 1);
      makeHingeTransform(progress, tmpMatrix);
      let uvXInc = hingeSegmentLength * (i + 1);
      prevQuad = this.addQuad({
        bottomLeft: prevQuad.topLeft,
        bottomRight: prevQuad.topRight,
        topLeft: createBezelVertex(
          this.width - this.rightBezel,
          0,
          -surfaceLength - flattenHingeHlfLength,
          tmpMatrix,
          uvXInc,
        ),
        topRight: createBezelVertex(
          this.width,
          0,
          -surfaceLength - flattenHingeHlfLength,
          tmpMatrix,
          uvXInc,
        ),
      });
    }

    // Top right side.
    let uvXInc = this.hingeLength;
    let topZ = -this.height + this.hingeLength - flattenHingeHlfLength * 2;
    makeHingeTransform(1, tmpMatrix);
    prevQuad = this.addQuad({
      bottomRight: prevQuad.topRight,
      bottomLeft: prevQuad.topLeft,
      topRight: createBezelVertex(
        this.width,
        0,
        topZ + this.topBezel,
        tmpMatrix,
        uvXInc,
      ),
      topLeft: createBezelVertex(
        this.width - this.rightBezel,
        0,
        topZ + this.topBezel,
        tmpMatrix,
        uvXInc,
      ),
    });

    // Top right corner.
    topZ = -this.height + this.hingeLength - flattenHingeHlfLength * 2;
    prevQuad = this.addQuad({
      bottomRight: prevQuad.topRight,
      bottomLeft: prevQuad.topLeft,
      topRight: createBezelVertex(this.width, 0, topZ, tmpMatrix, uvXInc),
      topLeft: createBezelVertex(
        this.width - this.rightBezel,
        0,
        topZ,
        tmpMatrix,
        uvXInc,
      ),
    });

    // Top side.
    prevQuad = this.addQuad({
      bottomRight: prevQuad.bottomLeft,
      topRight: prevQuad.topLeft,
      bottomLeft: createBezelVertex(
        this.leftBezel,
        0,
        topZ + this.topBezel,
        tmpMatrix,
        uvXInc,
      ),
      topLeft: createBezelVertex(this.leftBezel, 0, topZ, tmpMatrix, uvXInc),
    });

    // Top right corner.
    prevQuad = this.addQuad({
      bottomRight: prevQuad.bottomLeft,
      topRight: prevQuad.topLeft,
      bottomLeft: createBezelVertex(
        0,
        0,
        topZ + this.topBezel,
        tmpMatrix,
        uvXInc,
      ),
      topLeft: createBezelVertex(0, 0, topZ, tmpMatrix, uvXInc),
    });

    // Top left side
    prevQuad = this.addQuad({
      topLeft: prevQuad.bottomLeft,
      topRight: prevQuad.bottomRight,
      bottomRight: createBezelVertex(
        this.leftBezel,
        0,
        -(surfaceLength + flattenHingeHlfLength),
        tmpMatrix,
        uvXInc,
      ),
      bottomLeft: createBezelVertex(
        0,
        0,
        -(surfaceLength + flattenHingeHlfLength),
        tmpMatrix,
        uvXInc,
      ),
    });

    // Left Hinge.
    for (let i = this.hingeSegments - 1; i >= 0; i--) {
      let uvXInc = hingeSegmentLength * i;
      makeHingeTransform(i / this.hingeSegments, tmpMatrix);
      prevQuad = this.addQuad({
        bottomLeft: createBezelVertex(
          0,
          0,
          -surfaceLength - flattenHingeHlfLength,
          tmpMatrix,
          uvXInc,
        ),
        bottomRight: createBezelVertex(
          this.leftBezel,
          0,
          -surfaceLength - flattenHingeHlfLength,
          tmpMatrix,
          uvXInc,
        ),
        topLeft: prevQuad.bottomLeft,
        topRight: prevQuad.bottomRight,
      });
    }
    this.addQuad({
      topLeft: prevQuad.bottomLeft,
      topRight: prevQuad.bottomRight,
      bottomLeft: startLeft,
      bottomRight: startRight,
    });
  }

  private addQuad(quad: Quad): QuadIndices {
    let {
      top: topLeft,
      right: bottomRight,
      left: bottomLeft,
    } = this.addTriangle(quad.topLeft, quad.bottomRight, quad.bottomLeft);
    let { right: topRight } = this.addTriangle(
      topLeft,
      quad.topRight,
      quad.bottomRight,
    );
    return { topLeft, topRight, bottomLeft, bottomRight };
  }

  private addTriangle(
    top: Vertex | number,
    right: Vertex | number,
    left: Vertex | number,
  ): TriangleIndices {
    let topIdx: number =
      typeof top === "number"
        ? top
        : this.pushVertex(top.position, top.normal, top.uv);
    let leftIdx: number =
      typeof left === "number"
        ? left
        : this.pushVertex(left.position, left.normal, left.uv);
    let rightIdx: number =
      typeof right === "number"
        ? right
        : this.pushVertex(right.position, right.normal, right.uv);
    this.addVertexIndices([topIdx, leftIdx, rightIdx]);
    return { top: topIdx, left: leftIdx, right: rightIdx };
  }

  private pushVertex(
    position: Vector3,
    normal: Vector3,
    uv: [number, number],
  ): number {
    position.toArray(this.positions, this.totalPositions * 3);
    normal.toArray(this.normals, this.totalPositions * 3);
    this.uvs[this.totalPositions * 2] = uv[0];
    this.uvs[this.totalPositions * 2 + 1] = uv[1];
    let vertexIndex = this.totalPositions;
    this.totalPositions += 1;
    return vertexIndex;
  }

  private addVertexIndices(vertexIndices: number[]) {
    this.indices.set(vertexIndices, this.totalIndices);
    this.totalIndices += vertexIndices.length;
  }

  public setIsDisplayOn(isDisplayOn: boolean): void {
    this.isDisplayOn = isDisplayOn;
    this.displayMaterial.roughness = 0.3;
    this.displayMaterial.metalness = 0.2;
    if (this.isDisplayOn) {
      this.displayMaterial.map = this.displayTexture;
      this.displayMaterial.emissiveMap = this.displayTexture;
      this.displayMaterial.color = new Color(0xffffff);
      this.displayMaterial.emissive = new Color(0xffffff);
    } else {
      this.displayMaterial.map = null;
      this.displayMaterial.emissiveMap = null;
      this.displayMaterial.color = new Color(this.displayColor);
      this.displayMaterial.emissive = new Color(0x000000);
    }
    this.displayMaterial.needsUpdate = true;
  }

  public getIsDisplayOn(): boolean {
    return this.isDisplayOn;
  }

  public dispose(): void {
    // TODO
  }
}

/**
 * @param hingeSegments The number of segments in the computer hinge.
 * @returns The number of vertices required to create the computer volume,
 * taking into account triangle vertices that can be merged.
 */
function getNumberOfIndices(hingeSegments: number): number {
  let rectVertices = 6;
  let faceVertices =
    // The two surfaces.
    rectVertices * 2 +
    // The hinge segments.
    hingeSegments * rectVertices;
  return (
    // Top, bottom, and sides.
    faceVertices * 4 +
    // The Bezel is equivalent to two faces and 6 rectangles.
    faceVertices * 2 +
    rectVertices * 6 +
    // Front and back.
    rectVertices * 2
  );
}

function getNumberOfVertices(hingeSegments: number): number {
  let faceIndices = 4 * 2 + hingeSegments * 4 - 4;
  return (
    // Top, bottom, and sides.
    faceIndices * 4 +
    // The Bezel is equivalent to two faces and 6 rectangles, and
    // 16 common vertices.
    faceIndices * 2 +
    4 * 6 -
    16 +
    // Front and back.
    4 * 2
  );
}

type Quad = {
  topLeft: number | Vertex;
  topRight: number | Vertex;
  bottomLeft: number | Vertex;
  bottomRight: number | Vertex;
};

type QuadIndices = {
  topLeft: number;
  topRight: number;
  bottomLeft: number;
  bottomRight: number;
};

type TriangleIndices = {
  top: number;
  left: number;
  right: number;
};

type Vertex = {
  normal: Vector3;
  position: Vector3;
  uv: [number, number];
};
