import { nanoid as uniqueId } from "nanoid";
import { StateCreator } from "zustand";

import { FORM_FACTORS } from "../../lib/form-factors";
import { MainStore } from "./types";

let url = new URL(window.location.href);
const operationMode =
  url.searchParams.get("mode") === "debug" ? "debug" : "normal";

const createStore: StateCreator<MainStore> = (set, get, api) => ({
  // App related parameters.
  localSaveStatus: "idle",
  localSaveError: null,

  exportStore() {
    return Promise.reject("exportStore not supported");
  },
  importStore() {
    return Promise.reject("importStore not supported");
  },

  operationMode,

  // Design selection.
  designs: new Map(),
  designOrder: [],
  currentDesignId: null,
  lookedUpDesignId: null,

  getDesign(id) {
    if (id == null) return null;
    return get().designs.get(id) ?? null;
  },

  reorderDesigns(designId: string, newIndex: number) {
    set((store) => {
      if (newIndex >= store.designOrder.length || newIndex < 0) {
        throw new Error(`Index out of bound`);
      }
      const designOrder = store.designOrder.filter((id) => id !== designId);
      if (designOrder.length === store.designOrder.length) {
        throw new Error(`Design not found: ${designId}`);
      }
      designOrder.splice(newIndex, 0, designId);
      return { designOrder };
    });
  },

  addDesign(name, image, orientation, formFactor) {
    if (!image.complete) {
      throw new Error(`Image is not loaded`);
    }

    let { currentDesignId, designs, designOrder } = get();

    let designRecord = {
      fileName: name,
      id: uniqueId(),
      orientation,
      image,
      formFactor,
    };

    // We update the current design, but only if the previous current design
    // was the last one.
    if (
      currentDesignId == null ||
      designOrder.length < 1 ||
      designOrder[designOrder.length - 1] === currentDesignId
    ) {
      currentDesignId = designRecord.id;
    }
    let newDesigns = new Map(designs);
    newDesigns.set(designRecord.id, designRecord);
    set({
      designs: newDesigns,
      currentDesignId,
      designOrder: [...designOrder, designRecord.id],
    });
    return designRecord.id;
  },

  removeDesign(designId) {
    let { currentDesignId, lookedUpDesignId, designOrder, designs } = get();
    let designIdx = designOrder.findIndex((id) => id === designId);
    if (designIdx < 0) {
      throw new Error(`Design ${designIdx} not found.`);
    }
    if (currentDesignId === designId) {
      // If the current design was the one being deleted, switch to the last
      // design (if any), or the one after if it was the first.
      if (designIdx === 0) {
        currentDesignId = designOrder[1] ?? null;
      } else {
        // In theory designOrder[designIdx - 1] should be defined, otherwise
        // designIdx would be 0, but we still transform it to null if
        // undefined just in case.
        currentDesignId = designOrder[designIdx - 1] ?? null;
      }
    }
    lookedUpDesignId = lookedUpDesignId === designId ? null : lookedUpDesignId;
    designs = new Map(designs);
    designs.delete(designId);
    designOrder = designOrder.filter((id) => id !== designId);
    set({ designs, currentDesignId, lookedUpDesignId, designOrder });
  },

  setCurrentDesign(designId) {
    if (!get().designs.has(designId)) {
      throw new Error(`Unknown design: ${designId}`);
    }
    set({ currentDesignId: designId });
  },

  setLookedUpDesign(designId) {
    set((store) => {
      if (designId == null) {
        return { lookedUpDesignId: null };
      }
      if (!store.designs.has(designId)) {
        throw new Error(`Unknown design: ${designId}`);
      }
      return { lookedUpDesignId: designId };
    });
  },

  setDesigns(designList, currentDesignId) {
    let designs = new Map(designList.map((d) => [d.id, d]));
    if (
      designs.size > 0 &&
      currentDesignId != null &&
      !designs.has(currentDesignId)
    ) {
      throw new Error(`Current design not in design list: ${currentDesignId}`);
    }
    let designOrder = designList.map((d) => d.id);

    set({ designOrder, designs, currentDesignId, lookedUpDesignId: null });
  },

  // Computer form factor.
  setDesignFormFactor(designId, formFactor) {
    let store = get();
    let newDesigns = new Map(store.designs);
    let targetDesign = newDesigns.get(designId);
    if (targetDesign == null) {
      throw new Error(`Unknown design: ${designId}`);
    }
    let newTargetDesign = { ...targetDesign, formFactor };
    newDesigns.set(designId, newTargetDesign);

    set({ ...store, designs: newDesigns });
  },

  defaultFormFactor: FORM_FACTORS.closed.values,

  setDefaultFormFactor(defaultFormFactor) {
    set({ defaultFormFactor });
  },
});

export default createStore;
