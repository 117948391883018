import styled from "@emotion/styled";
import React, { CSSProperties } from "react";
import { ValueOf } from "type-fest";
import shallow from "zustand/shallow";

import useUniqueId from "../hooks/useUniqueId";
import useMainStore, { MainStore } from "../stores/main";
import { ComputerFormFactor } from "../stores/main/types";

const InputLabel = styled.label({
  display: "contents",
  pointerEvents: "auto",
});

const ControlInput = styled.input(({ theme }) => ({
  display: "inline-block",
  marginLeft: theme.space[2],
  marginRight: theme.space[2],
  width: theme.sizes[5],
}));

const ControlText = styled.span<{
  textAlign: ValueOf<CSSProperties, "textAlign">;
  width?: ValueOf<CSSProperties, "width">;
}>(({ textAlign, width }) => ({
  display: "inline-block",
  textAlign,
  width,
}));

const Grid = styled.div(({ theme }) => ({
  margin: theme.space[2],
  display: "grid",
  gridTemplateColumns: "auto 1fr auto",
}));

export default function Controls(): React.ReactElement {
  const {
    currentDesignId,
    formFactor,
    setDesignFormFactor,
    setDefaultFormFactor,
  } = useMainStore(selector, shallow);

  let handleFormFactorChange = (
    partialFormFactor: Partial<ComputerFormFactor>,
  ) => {
    let newFormFactor = { ...formFactor, ...partialFormFactor };
    if (currentDesignId == null) {
      setDefaultFormFactor(newFormFactor);
    } else {
      setDesignFormFactor(currentDesignId, newFormFactor);
    }
  };

  let openingInputId = useUniqueId();
  let roundedOpening = Math.round(formFactor.openingAngle);

  return (
    <Grid>
      <InputLabel htmlFor={openingInputId}>
        <ControlText textAlign="right">Opening: </ControlText>
        <ControlInput
          id={openingInputId}
          type="range"
          min={0}
          max={360}
          step={0.1}
          value={formFactor.openingAngle}
          onChange={(evt) => {
            handleFormFactorChange({ openingAngle: Number(evt.target.value) });
          }}
        />
        <ControlText textAlign="left" width={`${"360 degrees".length}ch`}>
          {roundedOpening} degree{roundedOpening === 1 ? "" : "s"}
        </ControlText>
      </InputLabel>
      <PercentSlider
        value={formFactor.sidePositionProgress}
        onChange={(sidePositionProgress) => {
          handleFormFactorChange({ sidePositionProgress });
        }}
        label="Side Position"
      />
      <PercentSlider
        value={formFactor.landscapeOrientationProgress}
        onChange={(landscapeOrientationProgress) => {
          handleFormFactorChange({ landscapeOrientationProgress });
        }}
        label="Landscape Orientation"
      />
      <PercentSlider
        value={formFactor.reversedPositionProgress}
        onChange={(reversedPositionProgress) => {
          handleFormFactorChange({ reversedPositionProgress });
        }}
        label="Reversed Position"
      />
    </Grid>
  );
}

type PersenSliderProps = {
  value: number;
  onChange: (value: number) => void;
  label: string;
};

function PercentSlider({ value, onChange, label }: PersenSliderProps) {
  let inputId = useUniqueId();
  return (
    <InputLabel htmlFor={inputId}>
      <ControlText textAlign="right">{label}: </ControlText>
      <ControlInput
        id={inputId}
        type="range"
        min={0}
        max={1}
        step={0.001}
        value={value}
        onChange={(evt) => {
          let progress = Number(evt.target.value);
          onChange(progress);
        }}
      />
      <ControlText textAlign="left">{(value * 100).toFixed(0)}%</ControlText>
    </InputLabel>
  );
}

const selector = (store: MainStore) => {
  let formFactor =
    store.getDesign(store.currentDesignId)?.formFactor ??
    store.defaultFormFactor;
  return {
    formFactor,
    currentDesignId: store.currentDesignId,
    setDesignFormFactor: store.setDesignFormFactor,
    setDefaultFormFactor: store.setDefaultFormFactor,
  };
};
