import MenuFileInputItem, { MenuFileInputItemProps } from "./MenuFileInputItem";
import MenuButtonItem, { MenuButtonItemProps } from "./MenuItemButton";

export type MenuItemProps =
  | ({ type: "button" } & MenuButtonItemProps)
  | ({ type: "file input" } & MenuFileInputItemProps);
export default function MenuItem({
  type,
  ...otherProps
}: MenuItemProps): React.ReactElement {
  switch (type) {
    case "button":
      return <MenuButtonItem {...(otherProps as MenuButtonItemProps)} />;
    case "file input":
      return <MenuFileInputItem {...(otherProps as MenuFileInputItemProps)} />;
  }
}
