/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";

import buttonCSS from "./buttonCSS";
import MenuItemContent, { MenuItemContentProps } from "./MenuItemContent";

export type MenuButtonItemProps = MenuItemContentProps & {
  onSelect: () => void;
};

export default function MenuButtonItem({
  onSelect,
  ...contentProps
}: MenuButtonItemProps): React.ReactElement {
  const theme = useTheme();
  return (
    <button onClick={onSelect} css={buttonCSS(theme)}>
      <MenuItemContent {...contentProps} />
    </button>
  );
}
