import styled from "@emotion/styled";
import React from "react";

export type IconProps = {
  size: number;
};

const Svg = styled.svg({
  fill: "currentcolor",
});

export function Import({ size }: IconProps): React.ReactElement {
  return (
    <Svg viewBox="0 0 60.903 60.903" width={size} height={size}>
      <path
        d="M49.561,16.464H39.45v6h10.111c3.008,0,5.341,1.535,5.341,2.857v26.607c0,1.321-2.333,2.858-5.341,2.858H11.34
		c-3.007,0-5.34-1.537-5.34-2.858V25.324c0-1.322,2.333-2.858,5.34-2.858h10.11v-6H11.34C4.981,16.466,0,20.357,0,25.324v26.605
		c0,4.968,4.981,8.857,11.34,8.857h38.223c6.357,0,11.34-3.891,11.34-8.857V25.324C60.902,20.355,55.921,16.464,49.561,16.464z"
      />
      <path
        d="M39.529,29.004c-0.768,0-1.535,0.294-2.121,0.88l-3.756,3.755V20.612v-6V3.117c0-1.656-1.343-3-3-3s-3,1.344-3,3v11.494v6
		v13.23l-3.959-3.958c-0.586-0.586-1.354-0.88-2.121-0.88s-1.535,0.294-2.121,0.88c-1.172,1.17-1.172,3.07,0,4.241l8.957,8.957
		c0.586,0.586,1.354,0.877,2.12,0.877c0.008,0,0.016,0,0.023,0s0.015,0,0.022,0c0.768,0,1.534-0.291,2.12-0.877l8.957-8.957
		c1.172-1.171,1.172-3.071,0-4.241C41.064,29.298,40.298,29.004,39.529,29.004z"
      />
    </Svg>
  );
}

export function Export({ size }: IconProps): React.ReactElement {
  return (
    <Svg viewBox="0 0 67.671 67.671" width={size} height={size}>
      <path
        d="M52.946,23.348H42.834v6h10.112c3.007,0,5.34,1.536,5.34,2.858v26.606c0,1.322-2.333,2.858-5.34,2.858H14.724
		c-3.007,0-5.34-1.536-5.34-2.858V32.207c0-1.322,2.333-2.858,5.34-2.858h10.11v-6h-10.11c-6.359,0-11.34,3.891-11.34,8.858v26.606
		c0,4.968,4.981,8.858,11.34,8.858h38.223c6.358,0,11.34-3.891,11.34-8.858V32.207C64.286,27.239,59.305,23.348,52.946,23.348z"
      />
      <path
        d="M24.957,14.955c0.768,0,1.535-0.293,2.121-0.879l3.756-3.756v13.028v6v11.494c0,1.657,1.343,3,3,3s3-1.343,3-3V29.348v-6
		V10.117l3.959,3.959c0.586,0.586,1.354,0.879,2.121,0.879s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242l-8.957-8.957
		C35.492,0.291,34.725,0,33.958,0c-0.008,0-0.015,0-0.023,0s-0.015,0-0.023,0c-0.767,0-1.534,0.291-2.12,0.877l-8.957,8.957
		c-1.172,1.171-1.172,3.071,0,4.242C23.422,14.662,24.189,14.955,24.957,14.955z"
      />
    </Svg>
  );
}

export function Warning({ size }: IconProps): React.ReactElement {
  return (
    <Svg viewBox="0 0 486.463 486.463" width={size} height={size}>
      <path
        d="M243.225,333.382c-13.6,0-25,11.4-25,25s11.4,25,25,25c13.1,0,25-11.4,24.4-24.4
			C268.225,344.682,256.925,333.382,243.225,333.382z"
      />
      <path
        d="M474.625,421.982c15.7-27.1,15.8-59.4,0.2-86.4l-156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5s-59.4,16.3-74.9,43.4
			l-156.8,271.5c-15.6,27.3-15.5,59.8,0.3,86.9c15.6,26.8,43.5,42.9,74.7,42.9h312.8
			C430.725,465.582,458.825,449.282,474.625,421.982z M440.625,402.382c-8.7,15-24.1,23.9-41.3,23.9h-312.8
			c-17,0-32.3-8.7-40.8-23.4c-8.6-14.9-8.7-32.7-0.1-47.7l156.8-271.4c8.5-14.9,23.7-23.7,40.9-23.7c17.1,0,32.4,8.9,40.9,23.8
			l156.7,271.4C449.325,369.882,449.225,387.482,440.625,402.382z"
      />
      <path
        d="M237.025,157.882c-11.9,3.4-19.3,14.2-19.3,27.3c0.6,7.9,1.1,15.9,1.7,23.8c1.7,30.1,3.4,59.6,5.1,89.7
			c0.6,10.2,8.5,17.6,18.7,17.6c10.2,0,18.2-7.9,18.7-18.2c0-6.2,0-11.9,0.6-18.2c1.1-19.3,2.3-38.6,3.4-57.9
			c0.6-12.5,1.7-25,2.3-37.5c0-4.5-0.6-8.5-2.3-12.5C260.825,160.782,248.925,155.082,237.025,157.882z"
      />
    </Svg>
  );
}

export function Rotate({ size }: IconProps): React.ReactElement {
  return (
    <Svg viewBox="0 0 89.8 89.8" width={size} height={size}>
      <path d=" M 88.358 39.045 C 86.658 37.566 84.08 37.746 82.603 39.448 L 78.509 44.153 C 77.523 35.642 73.785 27.371 67.262 20.85 C 59.568 13.16 49.461 9.3 39.391 9.306 C 37.138 9.306 35.31 11.133 35.31 13.387 C 35.31 15.641 37.138 17.468 39.391 17.468 L 39.391 17.468 C 47.394 17.473 55.37 20.509 61.491 26.622 C 67.134 32.27 70.041 39.519 70.469 46.907 L 63.335 40.703 C 61.635 39.223 59.057 39.402 57.58 41.103 C 56.097 42.805 56.278 45.382 57.979 46.861 L 71.407 58.541 C 71.463 58.59 71.537 58.602 71.596 58.649 C 71.835 58.836 72.103 58.966 72.388 59.098 C 72.609 59.202 72.821 59.31 73.052 59.371 C 73.145 59.396 73.214 59.457 73.31 59.475 C 73.496 59.512 73.674 59.46 73.86 59.47 C 74.03 59.481 74.197 59.545 74.37 59.533 C 74.46 59.526 74.543 59.475 74.633 59.463 C 74.907 59.425 75.154 59.345 75.409 59.255 C 75.43 59.247 75.454 59.245 75.475 59.238 C 75.608 59.189 75.754 59.192 75.882 59.129 C 75.962 59.089 76.023 59.005 76.1 58.96 C 76.344 58.819 76.549 58.642 76.759 58.454 C 76.889 58.338 77.049 58.275 77.168 58.14 L 88.762 44.803 C 90.239 43.102 90.064 40.525 88.358 39.045 Z " />
      <path d=" M 45.056 35.441 C 44.293 34.678 43.26 34.243 42.171 34.243 L 4.081 34.243 C 2.994 34.243 1.961 34.678 1.198 35.441 C 0.435 36.202 0 37.236 0 38.324 L 0 76.413 C 0 77.502 0.436 78.535 1.198 79.297 C 1.961 80.059 2.994 80.494 4.081 80.494 L 42.17 80.494 C 43.259 80.494 44.293 80.059 45.055 79.297 C 45.815 78.535 46.251 77.502 46.251 76.413 L 46.251 38.324 C 46.252 37.236 45.816 36.202 45.056 35.441 Z  M 38.09 72.333 L 8.162 72.333 L 8.162 42.406 L 38.089 42.406 L 38.089 72.333 L 38.09 72.333 Z " />
    </Svg>
  );
}
